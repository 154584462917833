package mainmenu

import auth.TermsStore
import auth.verifyAgreeDate
import com.tryformation.localization.Locale
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.components.checkbox
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.p
import dev.fritz2.components.compat.span
import dev.fritz2.components.dropdown
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.Tag
import dev.fritz2.core.src
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.Style
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.LocaleStore
import localization.Locales
import localization.TL
import localization.Translation
import mainmenu.termstexts.disclaimerEN
import mainmenu.termstexts.privacyTextDE
import mainmenu.termstexts.privacyTextEN
import mainmenu.termstexts.termsTextDE
import mainmenu.termstexts.termsTextEN
import model.TermsState
import model.agreeDate
import model.agreedLatest
import model.read
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import search.separationLine
import theme.FormationColors
import theme.FormationDefault
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import utils.formatDateLong
import utils.makeRGBA
import utils.merge
import utils.parseInstant
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.contentScrollBox
import webcomponents.mainTitle
import webcomponents.oneButtonFooter

class TermsLanguageStore(localeStore: LocaleStore) : RootStore<Locale>(
    initialData = getTermsLang(localeStore.current),
    job = Job(),
) {

    private val syncedUserPreferencesStore: SyncedUserPreferencesStore by koinCtx.inject()

    private val syncUserPrefs = handle<Locale> { _, locale ->
        syncedUserPreferencesStore.updateLanguage(locale.id)
        locale
    }

    init {
        data handledBy syncUserPrefs
    }

    companion object {
        fun getTermsLang(locale: Locale): Locales {
            return when (locale) {
                Locales.DE_DE -> Locales.DE_DE
                else -> Locales.EN_US
            }
        }
    }
}

fun RenderContext.pageTermsAndConditions() {
    val termsStore: TermsStore by koinCtx.inject()
    val readStateFlow = termsStore.map(TermsState.read())
    val agreeDateFlow = termsStore.map(TermsState.agreeDate())
    val agreeStateFlow = termsStore.map(TermsState.agreedLatest())
    val currentAgreeState = agreeStateFlow.current
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val termsLanguageStore: TermsLanguageStore by koinCtx.inject()

    baseLayout(
        expandable = false,
        header = {
            div({
                css("align-self: end;")
                width { maxContent }
            }) { languageSwitch(store = termsLanguageStore) }
            mainTitle(translation[TL.PageTerms.PAGE_TITLE])
        },
        content = {
            contentScrollBox {

                termsLanguageStore.data.render { locale ->
                    agreeStateFlow.data.render { state ->
                        if (state != true) termsInfoBox()
                    }
                    when (locale) {
                        Locales.DE_DE -> termsDE()
                        else -> termsEN()
                    }
                }

                div({
                    width { auto }
                    maxWidth { maxContent }
                    display { flex }
                    margins {
                        vertical { normal }
                        horizontal { small }
                    }
                    border { color { FormationColors.BlueDeep.color }; width(formationStyles.borderWidth) }
                    radius(formationStyles.buttonRadius)
                    padding { small }
                }) {
                    checkbox {
                        label(translation[TL.PageTerms.I_HAVE_READ_T_AND_C])
                        checked(readStateFlow.data)
                        enabled(agreeStateFlow.data.map { it?.let { !it } ?: true })
                        events {
                            with(this@pageTermsAndConditions) {
                                changes handledBy termsStore.read
                            }
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = combine(
                    agreeStateFlow.data, translation[TL.General.BACK], translation[TL.PageTerms.I_AGREE]
                ) { state, back, agree -> Triple(state, back, agree) }.map { (state, back, agree) -> (if (state == true) back else agree) },
                state = readStateFlow.data,
                width = { "320px" },
                value = Unit,
                clickHandlers = if (currentAgreeState == true) listOf(routerStore.back) else listOf(termsStore.agree, routerStore.back),
                tracker = termsStore.agreeingTerms,
                loadingText = translation[TL.PageTerms.BUTTON_LOADING_TEXT]
            )
            agreeDateFlow.data.render { date ->
                if (date != null && verifyAgreeDate(date)) {
                    p({
                        width { full }
                        textAlign { center }
                        fontSize { tiny }
                        fontWeight { lighter }
                        margins { bottom { "-1.05rem" } }
                    }) {
                        translation[TL.PageTerms.AGREE_DATE_INFO, mapOf("date" to (date.parseInstant()?.formatDateLong() ?: "?"))].renderText(into = this)
                    } // 21st of December 2020
                }
            }
        }
    )
}

/**
 * English terms and privacy texts
 */

fun RenderContext.termsEN() {
    disclaimerEN()
    termsTextEN()
    div({
        width { full }
        margins { vertical { larger } }
    }) {
        separationLine()
    }
    privacyTextEN()
    disclaimerEN()
}


/**
 * German terms and privacy texts
 */

fun RenderContext.termsDE() {
    termsTextDE()
    div({
        width { full }
        margins { vertical { larger } }
    }) {
        separationLine()
    }
    privacyTextDE()
}


/**
 * Helping FUNCTIONS
 */


fun RenderContext.textPart(title: String? = null, number: Int? = null, content: HtmlTag<HTMLDivElement>.() -> Unit) {
    div {
        if (number != null || !title.isNullOrBlank()) {
            h3 { +"${number?.let { "$it. " } ?: ""}${if (!title.isNullOrBlank()) title.uppercase() else ""}" }
            br { }
        }
        content.invoke(this)
        br { }
        br { }
    }
}

fun RenderContext.textSubPart(title: String? = null, number: Double? = null, content: HtmlTag<HTMLDivElement>.() -> Unit) {
    div {
        if (number != null || !title.isNullOrBlank()) {
            h4 { +"${number?.let { "$it. " } ?: ""}${if (!title.isNullOrBlank()) title.uppercase() else ""}" }
        }
        content.invoke(this)
        br { }
    }
}

fun RenderContext.textPartTitle(title: String, number: Int? = null, content: HtmlTag<HTMLDivElement>.() -> Unit) {
    div {
        span({
            fontSize { large }
            fontWeight { bold }
        }) { +"${number?.let { "$it. " } ?: ""}${title.uppercase()}" }
        br { }
        content.invoke(this)
        br { }
        br { }
    }
}

fun RenderContext.textParagraphs(content: Tag<HTMLElement>.() -> Unit) {
    stackUp({
        width { full }
        alignItems { stretch }
    }) {
        spacing { normal }
        items {
            content.invoke(this)
        }
    }
}

val termsLinkStyle: Style<BasicParams> = {
    color { FormationColors.MarkerYou.color }
    hover {
        color { makeRGBA(FormationColors.MarkerYou.color, 0.5) }
    }
    textDecoration { underline }
}

enum class LanguageSwitchType {
    Terms, Disclaimer
}

fun RenderContext.languageSwitch(store: RootStore<Locale>, type: LanguageSwitchType = LanguageSwitchType.Terms) {
    val translation: Translation by koinCtx.inject()
    val langs = when (type) {
        LanguageSwitchType.Terms -> listOf(Locales.DE_DE, Locales.EN_US)
        LanguageSwitchType.Disclaimer -> Locales.getNonFictional() // always use non-fictional languages for disclaimer
    }
    dropdown({
        color { secondary.main }
        background {
            color { primary.main }
        }
        margins {
            top { small }
        }
    }) {
        placement { bottom }
        alignment { end }
        toggle {
            lineUp({
                alignItems { center }
                justifyContent { center }
                css("cursor: pointer;")
                width { maxContent }
            }) {
                spacing { none }
                items {
                    store.data.render { locale ->
                        val path = Locales.findByIdOrNull(locale.id)?.flagImagePath
                        if (!path.isNullOrBlank()) {
                            img({
                                size { "24px" }
                            }) {
                                src(path)
                            }
                        } else {
                            icon({
                                size { "24px" }
                            }) { fromTheme { FormationIcons.Globe.icon } }
                        }
                    }
                    icon({
                        size { "24px" }
                        margins { left { small } }
                    }) { fromTheme { chevronDown } }
                }
            }
        }
        content {
            store.data.render { activeLocale ->
                stackUp({
                    padding { tiny }
                    width { "300px" }
                    fontSize { small }
                }) {
                    spacing { tiny }
                    items {
                        langs.forEach { locale ->
                            lineUp({
                                width { full }
                                alignItems { center }
                                justifyContent { start }
                                padding { tiny }
                                css("cursor: pointer;")
                                radius(formationStyles.buttonRadius)
                                if (activeLocale == locale) {
                                    fontWeight { bold }
                                } else {
                                    fontWeight { lighter }
                                    hover {
                                        fontWeight { bold }
                                    }
                                }
                            }) {
                                spacing { small }
                                items {
                                    val flagImagePath = locale.flagImagePath
                                    if (!flagImagePath.isNullOrBlank()) {
                                        img({
                                            size { "24px" }
                                        }) {
                                            src(flagImagePath)
                                        }
                                    } else {
                                        icon({
                                            size { "24px" }
                                        }) { fromTheme { FormationIcons.Globe.icon } }
                                    }
                                    span {
//                                        +"${translation.getString(locale)} (${locale.id})"
                                        translation[locale].merge(flowOf("(${locale.id})")).renderText(into = this)
                                    }
                                }
                                events {
                                    clicks.map { locale } handledBy store.update
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.termsInfoBox() {
    val defaultTheme = koinCtx.get<FormationDefault>()
    val translation: Translation by koinCtx.inject()
    flexBox({
        color { FormationColors.YellowDoing.color }
        background {
            color { makeRGBA(FormationColors.YellowDoing.color, 0.1) }
        }
        border {
            color { FormationColors.YellowDoing.color }
            width(formationStyles.borderWidth)
        }
        padding { small }
        margin { large }
        radius(formationStyles.buttonRadius)
    }) {
        cardSubtitle(translation[TL.PageTerms.INFO_TEXT], iconFlow = flowOf(FormationUIIcons.ArrowDown.icon), iconPosition = Position.Right)
    }
}

/**
 * Old terms texts
 */

fun RenderContext.termsText() {
    div({
        width { full }
    }) {
        h2 { +"FORMATION" }
        h2 { +"Datensicherheit" }
        h5 { +"28. April 2021" }
        br { }
        br { }
        br { }
        h1 { +"Übersicht" }
        br { }
        p { +"FORMATION GmbH Workplace Technologies (später FORMATION, wir, unsere oder uns) betreibt www.tryformation.com und alle zugehörige Software. FORMATION ist ein Datenverantwortlicher und muss bestimmte Informationen (Datenverarbeitung) über Personen (betroffene Personen) sammeln und verwenden, die eine natürliche Person direkt oder indirekt identifizieren können - die Verarbeitung von personenbezogenen Daten einer betroffenen Person.\n" }
        br { }
        p { +"Dazu gehören Besucher unserer Website, Kunden, Lieferanten, Geschäftskontakte, Mitarbeiter und andere Personen, mit denen FORMATION in Beziehung steht oder die sie möglicherweise für geschäftliche Interessen oder Informationsbereitstellung kontaktieren müssen. Diese Richtlinie beschreibt:" }
        br { }
        br { }
        br { }
        h2 { +"Gründe" }
        br { }
        p { +"Welche Gründe haben wir für die Verarbeitung Ihrer persönlichen Daten?" }
        br { }
        p { +"Je nach Geschäftsgegenstand haben wir unterschiedliche Gründe für die Verarbeitung Ihrer personenbezogenen Daten." }
        br { }
        p { +"Solche Gründe können sein:" }
        br { }
        ul {
            li { p { +"Vorbereitung, Durchführung und Analyse von Demonstrationen unserer Software zur Verbesserung der Benutzererfahrung" } }
            li { p { +"Nutzung unserer Software" } }
            li { p { +"Analyse des Benutzerverhaltens" } }
            li { p { +"um unser Publikum über Neuigkeiten in Bezug auf unser Produkt und die Branche zu informieren" } }
            li { p { +"potenzielle Kunden zu kontaktieren" } }
            li { p { +"unsere vertraglichen Verpflichtungen zu erfüllen" } }
            li { p { +"die gesetzlichen Anforderungen zu erfüllen" } }
        }
        br { }
        br { }
        br { }
        h2 { +"Art der Daten" }
        br { }
        p { +"Welche personenbezogenen Daten werden verarbeitet?" }
        br { }
        p { +"Abhängig von der Geschäftsbeziehung sammeln wir personenbezogene Daten, um einen besseren Service und bessere Angebote zu bieten." }
        br { }
        p { +"Zu diesen Daten können gehören:" }
        br { }
        ul {
            li { p { +"personenbezogene Daten, die Sie direkt identifizieren können, wie z. B. Ihr Name, Ihre Adresse, Ihre E-Mail-Adresse, freiwillige Antworten auf Fragen zu Ihrem Unternehmen und Ihren Bedürfnissen, Ihr Verhalten in unserer Software, insbesondere aber nicht beschränkt auf Daten zur Lokalisierung ihres physischen Standorts (sofern sie diesem gesondert zustimmen)" } }
            li { p { +"personenbezogene Daten, die Sie indirekt identifizieren können, z. B. Daten, die wir für statistische Analysen sammeln (Browserverhalten), ohne jedoch auf Ihre IP-Adresse beschränkt zu sein. Wir verwenden mehrere Tracking-Tools, um Informationen über die Nutzung unserer Website zu sammeln. Dazu gehören Google Analytics, LinkedIn und Hubspot, die einen anonymen Datensatz bereitstellen, der angibt, wie und wo Benutzer mit unserer Website interagiert haben, Dauer des Besuchs, Ein- und Ausstiegspunkte auf unserer Website und für diejenigen, die sich ebenfalls für die Datenrichtlinie von Google entschieden haben, demografische Statistiken über den Benutzer." } }
            li { p { +"Personenbezogene Daten, die Sie indirekt identifizieren können, z. B. eine spezifische Nachverfolgung der Nutzung und des Verhaltens auf unserer Website, z.B. als Reaktion auf E-Mails oder Online-Anzeigen, für den Fall, dass Sie einer Nachverfolgung ihres Verhaltens zugestimmt haben" } }
        }
        br { }
        br { }
        br { }
        h2 { +"Verarbeitungsort" }
        br { }
        p { +"Wo werden die Daten verarbeitet?" }
        br { }
        p { +"Unsere Daten werden auf Servern gespeichert, die von Google LLC, der Telekom AG, der LinkedIn Corporation, Hubspot, Inc., Asana, Inc., Slack Technologies, Inc. bereitgestellt werden, mit denen FORMATION Vereinbarung zur Auftragsdatenvereinbarung geschlossen hat." }
        br { }
        p { +"Wo es benötigt wird und nur so lange wie nötig, werden personenbezogene Daten auch auf lokalen Computern wie Laptops und Desktop-Computern verarbeitet und gespeichert." }
        br { }
        br { }
        br { }
        h2 { +"Rechtliche Zwecke" }
        br { }
        p { +"Was ist der rechtliche Zweck der Datenverarbeitung?" }
        br { }
        p { +"FORMATION verarbeitet personenbezogene Daten nur, wenn wir einen legitimen Zweck dafür haben. Diese Zwecke variieren je nach Geschäftsbeziehung" }
        br { }
        p { +"Einige der Zwecke können sein:" }
        br { }
        ul {
            li { p { +"Sie geben uns Ihre Zustimmung" } }
            li { p { +"die Verarbeitung ist für die Ausführung eines Vertrags erforderlich, insbesondere aber nicht begrenzt auf die Nutzung unserer Software" } }
            li { p { +"die Verarbeitung ist erforderlich, um einer gesetzlichen Verpflichtung nachzukommen, welcher FORMATION als Datenverantwortlicher unterliegt" } }
        }
        br { }
        br { }
        br { }
        h2 { +"Rechte der betroffenen Person" }
        br { }
        p { +"Welche Rechte hat die betroffene Person?" }
        br { }
        p { +"Die betroffene Person übt bereits durch Lesen dieser Datenschutzerklärung bestimmte Rechte aus, beispielsweise das Recht, darüber informiert zu werden, was, wo und wer Daten verarbeitet und kontrolliert." }
        br { }
        p { +"Weitere Rechte können durch Schreiben einer E-Mail an unseren Datenschutzbeauftragten ausgeübt werden, der alle Anfragen innerhalb eines angemessenen Zeitraums beantwortet." }
        br { }
        p { +"Weitere Rechte sind:" }
        br { }
        ul {
            li { p { +"das Recht auf Löschung seiner persönlichen Daten (das Recht auf Vergessenwerden)" } }
            li { p { +"das Recht auf Berichtigung seiner persönlichen Daten" } }
            li { p { +"das Recht, die Verarbeitung seiner persönlichen Daten einzuschränken" } }
            li { p { +"das Recht, alle persönlichen Daten, in einem allgemein verwendeten, maschinenlesbaren Format zu erhalten, die ihn oder sie betreffen" } }
            li { p { +"Alle Rechte gemäß EU-DSGVO Art. 12 - 23" } }
        }
        br { }
    }
}

fun RenderContext.digitalXTerms() {
    div({
        width { full }
    }) {
        h3 { +"Liebe Nutzerin und Nutzer, \n" }
        br { }
        h3 { +"wir freuen uns, Sie im Rahmen dieser Demonstration auf unserer Plattform begrüßen zu dürfen. Hierbei handelt es sich ausdrücklich um eine Demo-Version." }
        br { }
        h3 { +"Für die Nutzung gelten die unten aufgeführten, allgemeinen Geschäftsbedingungen (AGBs). Mit der Nutzung der Demo-Version akzeptieren Sie die geltenden AGBs." }
        br { }
        br { }
        h2 { +"Nutzungsrecht" }
        br { }
        p { +"Die Demo-Version darf sowohl im privaten, nichtkommerziellen Bereich von Privatpersonen als auch im gewerblichen, kommerziellen Bereich von Angestellten und Unternehmern kostenlos verwendet werden. Da es sich hierbei um eine Demo-Version handelt, kann dieses Nutzungsrecht jederzeit, ohne vorherige Ankündigung entzogen werden. Dies geschieht durch Löschung der jeweiligen Nutzerkontos." }
        br { }
        h2 { +"Urheberrecht" }
        br { }
        p { +"Die Software wurde von FORMATION GmbH erstellt und ist urheberrechtlich geschützt." }
        br { }
        h2 { +"Gewährleistung" }
        br { }
        p { +"Gemäß Stand der Technik ist es nicht möglich, Anwendungsprogramme zu erstellen, die in allen Anwendungsfällen fehlerfrei arbeitet. Die Demo-Version wird „wie sie ist\" zur Verfügung gestellt. Separate Programmdokumentationen werden nicht bereitgestellt. Maßgebend für den Programmablauf ist immer die Menü- und Dialogsteuerung der Demo-Version. Der Autor übernimmt keine Garantie dafür, dass die Freeware bei Systemänderungen jederzeit funktionsfähig bleibt. Die Verantwortung für die richtige Auswahl und den richtigen Einsatz der Demo-Version, und damit auch für die Folgen, die sich aus dem Einsatz der Software ergeben, trägt der Endverbraucher." }
        br { }
        p { +"Sollten eventuell Hardware bzw. Betriebssysteme für die Freeware nicht geeignet sein, können daraus keine Rechte hergeleitet werden." }
        br { }
        p { +"Gemäß §523 und 524 BGB übernimmt der Autor nur eine Gewährleistung für arglistig verschwiegene Fehler.\n" }
        br { }
        h2 { +"Haftung" }
        br { }
        p { +"Gemäß § 521 BGB haftet der Autor der Demo-Version nicht für Schäden an Soft- oder Hardware oder Vermögensschäden, die durch das Benutzen des Programms entstehen, es sei denn diese beruhen auf einem grob fahrlässigen oder vorsätzlichen Handeln des Autors, seiner Erfüllungsgehilfen oder seiner gesetzlichen Vertreter. Für Schäden an der Gesundheit, dem Körper oder dem Leben des Nutzers haftet der Autor uneingeschränkt. Ebenso haftet er für die Verletzung von Pflichten, die zur Erreichung des Vertragszwecks von besonderer Bedeutung sind (Kardinalspflichten)." }
        br { }
    }
}
