package services

import apiclient.FormationClient
import apiclient.auth.ApiUser
import apiclient.auth.restLoginToWorkspace
import apiclient.users.FullUserProfile
import apiclient.users.TermsAndConditionStatus
import apiclient.users.UserChange
import apiclient.users.UserPreferences
import apiclient.users.applyUserChanges
import apiclient.users.deleteProfilePhoto
import apiclient.users.restAcceptTermsAndConditions
import apiclient.users.restChangePassword
import apiclient.users.restGetTermsAndConditionsStatus
import apiclient.users.restGetUserPreferences
import apiclient.users.restGetUserProfile
import apiclient.users.restUpdateUserPreferences
import apiclient.users.setProfilePhoto

class UserService(private val formationClient: FormationClient) {

    suspend fun getFullUserProfileById(userId: String): Result<FullUserProfile> {
        val result = formationClient.restGetUserProfile(userId = userId)
        return result.also { r ->
            if (r.isFailure) {
                console.error("getUserById: ", r.exceptionOrNull())
            }
        }
    }

    suspend fun getUserPreferencesById(userId: String): Result<UserPreferences> {

        val result = formationClient.restGetUserPreferences(userId = userId)
        return result.also { r ->
            if (r.isFailure) {
                console.error("getUserPreferencesById: ", r.exceptionOrNull())
            }
        }
    }

    suspend fun getTAndCStatus(userId: String): TermsAndConditionStatus? {

        val result = formationClient.restGetTermsAndConditionsStatus(userId = userId)
        return if (result.isSuccess) {
            result.getOrNull()
        } else {
            console.error("getTermsAndConditionStatus: ", result.exceptionOrNull())
            null
        }
    }

    suspend fun acceptTAndC(userId: String): TermsAndConditionStatus? {
        val result = formationClient.restAcceptTermsAndConditions(userId)
        return if (result.isSuccess) {
            result.getOrNull()
        } else {
            console.error("accept terms & conditions: ", result.exceptionOrNull())
            null
        }
    }

    suspend fun updateUserDetails(
        userId: String,
        firstName: String?,
        lastName: String?,
        jobTitle: String?,
        phoneNumbers: List<String>?,
    ): Result<FullUserProfile> {
        val removeNumbers = formationClient.restGetUserProfile(userId).getOrThrow().phoneNumbers - phoneNumbers.orEmpty().toSet()

        val result =
            formationClient.applyUserChanges(
                userId,
                listOfNotNull(
                    UserChange.FirstName(firstName.orEmpty()),
                    UserChange.LastName(lastName.orEmpty()),
                    UserChange.JobTitle(jobTitle.orEmpty()),
                    phoneNumbers?.let {
                        UserChange.AddPhoneNumbers(phoneNumbers.orEmpty())
                    },
                    phoneNumbers?.let {
                        if (removeNumbers.isNotEmpty()) {
                            UserChange.RemovePhoneNumbers(removeNumbers)
                        } else {
                            null
                        }
                    },
                ),
            )

        return result.also { r ->
            if (r.isFailure) {
                console.error("updateUserDetails: ", r.exceptionOrNull())
            }
        }
    }

    suspend fun updateUserProfilePhoto(
        userId: String,
        imageBytes: ByteArray,
        imageType: String
    ): Result<FullUserProfile> {
        return formationClient.setProfilePhoto(userId, imageBytes, imageType)
    }

    suspend fun deleteUserProfilePhoto(
        userId: String,
    ): Result<FullUserProfile> {
        return formationClient.deleteProfilePhoto(userId)
    }

    suspend fun updateUserPreferences(
        userId: String,
        preferences: UserPreferences,
    ): Result<UserPreferences> {
        val result =
            formationClient.restUpdateUserPreferences(
                userId = userId,
                userPreferences = preferences,
            )
        return result.also { r ->
            if (r.isFailure) {
                console.error("updateUserPreferences: ", r.exceptionOrNull())
            }
        }
    }

    suspend fun updatePassword(
        userId: String,
        oldPassword: String,
        newPassword: String,
    ): Pair<FullUserProfile?, String?> {
        val result = formationClient.restChangePassword(oldPassword = oldPassword, newPassword = newPassword, userId = userId)

        return if (result.isSuccess) {
            Pair(result.getOrThrow(), null)
        } else {
            Pair(null, result.exceptionOrNull()?.message)
        }
    }

    suspend fun validatePassword(
        workspace: String,
        email: String,
        password: String,
    ): Result<ApiUser> {
        val result = formationClient.restLoginToWorkspace(workspace = workspace, email = email, password = password)
        return result.also { r ->
            if (r.isFailure) {
                console.error("login: ", r.exceptionOrNull())
            }
        }
    }
}
