package data.objects.views.objectrouting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import location.GeoPositionStore
import location.geolocation.latLon
import qrcode.ScannedCodeStore
import utils.formatDateTime

enum class PositionType {
    ScannedObject, GPS
}

data class LastKnownPosition(
    val type: PositionType,
    val latLon: LatLon? = null,
    val geoObjectDetails: GeoObjectDetails? = null,
    val timeStamp: Instant
)

class LastKnownPositionStore : RootStore<LastKnownPosition?>(null, Job()) {

    val geoPositionStore: GeoPositionStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()

    val initialize = handle {
        null
    }

    val print = SimpleHandler<LastKnownPosition?> { data, job ->
        data handledBy {
            console.log("Last known location updated to: ${it?.latLon} (${it?.type?.name}@${it?.timeStamp?.formatDateTime()})")
        }
    }

    init {
        geoPositionStore.data.map { geoPosition ->
            geoPosition?.latLon()?.let {
                LastKnownPosition(
                    type = PositionType.GPS,
                    latLon = it,
                    timeStamp = Clock.System.now(),
                )
            }
        } handledBy update
        data handledBy print
    }
}
