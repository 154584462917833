package qrcode

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.FormationClient
import apiclient.geoobjects.Action
import apiclient.geoobjects.CodeLookupResult
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.LinkRel
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.TaskTemplate
import apiclient.geoobjects.UpdatePointLocation
import apiclient.geoobjects.applyObjectChanges
import apiclient.geoobjects.lookupCode
import apiclient.groups.getGroupNameByExternalCode
import apiclient.tags.actionIdMap
import apiclient.tags.buildingId
import apiclient.tags.floorId
import apiclient.tags.getUniqueTag
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import data.ObjectAndUserHandler
import data.objects.ActiveObjectStore
import data.objects.building.ActiveBuildingOverrideStore
import data.objects.building.BuildingOverride
import data.objects.building.CurrentBuildingsStore
import data.objects.views.attachments.PreAttachmentsStore
import data.objects.views.objectrouting.LastKnownPosition
import data.objects.views.objectrouting.LastKnownPositionStore
import data.objects.views.objectrouting.PositionType
import data.users.ActiveUserStore
import data.users.profile.VerifiedUserStore
import dev.fritz2.core.Id
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import koin.withKoin
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import location.GeoPositionStore
import login.ChangeWorkspaceStore
import login.CredentialsStore
import mainmenu.Pages
import mainmenu.RouterStore
import map.Cards
import map.MapLayersStore
import maplibreGL.MaplibreMap
import model.CodeTech
import model.CodeType
import model.L
import model.NotificationType
import model.OperationType
import model.Overlay
import model.PreAttachment
import model.ScanPurpose
import model.ScannedCode
import overlays.AlertOverlayStore
import overlays.BusyStore
import routing.MainController
import services.GeoPositionService
import theme.FormationColors
import workspacetools.usermanagement.urlEncode

class ScannedCodeStore : RootStore<ScannedCode>(
    initialData = ScannedCode(),
    job = Job(),
) {

    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    private val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val mainController: MainController by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    private val verifiedUserStore: VerifiedUserStore by koinCtx.inject()
    private val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    private val scannedCodeTypeStore: ScannedCodeTypeStore by koinCtx.inject()
    private val lastKnownPositionStore: LastKnownPositionStore by koinCtx.inject()

    val updateCode = handle<String?> { current, newCode ->
        current.copy(extOrIntObjIdOrActionId = newCode)
    }

    val updateType = handle<CodeTech> { current, newType ->
        current.copy(codeTech = newType)
    }

    val checkManuallyEnteredCode = handle { current ->
        if (current.extOrIntObjIdOrActionId != null) {
            checkCodeActionFromScanOrUrl(current)
        }
        current
    }

    val checkManuallyEnteredCodeToConnect = handle { current ->
        val manualCodeInputStore by koinCtx.inject<ManualCodeInputStore>()
        val code = manualCodeInputStore.current
        if (code.isNotBlank()) {
            checkCodeActionFromScanOrUrl(
                current.copy(
                    extOrIntObjIdOrActionId = code,
                    scanPurpose = ScanPurpose.ConnectQRToObject,
                ),
            )
        }
        current
    }

    suspend fun checkCodeActionFromScanOrUrl(
        scannedCode: ScannedCode,
        route: Map<String, String>? = null,
    ) {
        val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
        val activeUserStore by koinCtx.inject<ActiveUserStore>()
        val busyStore by koinCtx.inject<BusyStore>()
        val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
        val credentialsStore: CredentialsStore by koinCtx.inject()
        val changeWorkspaceStore: ChangeWorkspaceStore by koinCtx.inject()

        val signInToken = scannedCode.loginToken
        val scannedId = scannedCode.extOrIntObjIdOrActionId
        val userId = scannedCode.userId
        val fallback = scannedCode.fallback
        val currentActiveObjectId = activeObjectStore.current.tags.getUniqueTag(ObjectTags.ExternalId) ?: activeObjectStore.current.id
        val currentActionIds = activeObjectStore.current.tags.actionIdMap
        val checkFromScan = route == null

        if (!signInToken.isNullOrBlank()) {
            credentialsStore.signInWithRefreshToken(signInToken)
            return
        } else {

            val groupId = currentWorkspaceStore.current?.groupId
                ?: apiUserStore.current.apiUser?.groups?.firstOrNull {
                    it.groupName == scannedCode.extOrIntObjIdOrActionId?.let {
                        apiUserStore.anonymousGraphQLClient.getGroupNameByExternalCode(scannedCode.extOrIntObjIdOrActionId).getOrNull()
                    }
                }?.groupId
                ?: apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId

            if (!scannedId.isNullOrBlank() && !groupId.isNullOrBlank()) {

                // 1. check if it is user verification and handle it
                // 2. else do code lookup and store result
                // 3. check scan purpose
                //a) connect
                //b) open

                when (scannedCode.scanPurpose) {
                    ScanPurpose.ConnectQRToObject -> {
                        busyStore.handleApiCall(
                            suspend {
                                formationClient.lookupCode(groupId = groupId, code = scannedId.urlEncode(), scan = scannedCode.scan)
                            },
                            successMessage = null,
                            processResult = { result ->
                                val codeUse = when {
                                    scannedCode.operation == OperationType.VERIFY -> CodeType.UserVerification
                                    else -> when (result) {
                                        is CodeLookupResult.NoAccess -> CodeType.NoAccess
                                        is CodeLookupResult.GeoObject -> CodeType.ObjectId
                                        is CodeLookupResult.ActionOnGeoObject -> CodeType.ActionTrigger
                                        is CodeLookupResult.User -> CodeType.User
                                        is CodeLookupResult.NotFound -> CodeType.Unused
                                        else -> CodeType.NotChecked
                                    }
                                }
                                updateCode(scannedCode.extOrIntObjIdOrActionId)
                                scannedCodeTypeStore.update(codeUse)
                                if (checkFromScan) {
                                    routerStore.addOrReplaceRoute(mapOf("show" to "connectQR"))
                                }
                            },
                            errorMessage = null,
                            processError = { error ->
                                // TODO handle error here
                            },
                        )
                    }

                    ScanPurpose.OpenCode -> {
                        when {
                            scannedCode.operation == OperationType.VERIFY -> {
                                console.log("USER VERIFICATION", scannedCode)
                                scannedCode.token?.let { verifiedUserStore.verifyAccessControlToken(it) }
                                routerStore.validateInternalRoute(Pages.UserVerificationResult.route)
                            }

                            else -> {
                                busyStore.handleApiCall(
                                    suspend {
                                        formationClient.lookupCode(groupId, scannedId.urlEncode(), scannedCode.scan)
                                    },
                                    successMessage = null,
                                    processResult = { result ->
                                        when (result) {
                                            is CodeLookupResult.NoAccess -> {
                                                console.log(
                                                    "You have no access to the object in \"${result.workspaceName}\", connected to the code:",
                                                    result.code,
                                                )
                                                alertOverlayStore.warnNotify(flowOf("You have no access to the object in \"${result.workspaceName}\", connected to the code: ${result.code}"))
                                                changeWorkspaceStore.changeWorkspacePopup(
                                                    oldRoute = Pages.Map.route,
                                                    oldWS = apiUserStore.current.apiUser?.workspaceName,
                                                    newRoute = Pages.Map.route + mapOf("id" to result.code, "ws" to result.workspaceName),
                                                    newWS = result.workspaceName,
                                                )
                                                routerStore.validateInternalRoute(Pages.Map.route)
                                            }

                                            is CodeLookupResult.GeoObject -> {
                                                val objectWithoutAction = result.result
                                                val objId = objectWithoutAction.tags.getUniqueTag(ObjectTags.ExternalId) ?: objectWithoutAction.id
                                                if (objId != currentActiveObjectId) {
                                                    console.log(
                                                        "Open object from code: ", result.code,
                                                        objectWithoutAction,
                                                    )
                                                    if (checkFromScan) {
                                                        alertOverlayStore.notify(flowOf("${scannedCode.codeTech?.name?.uppercase()}: $scannedId"))
                                                    }
                                                    // update LastKnownLocationStore with this scanned object
                                                    lastKnownPositionStore.update(
                                                        LastKnownPosition(
                                                            type = PositionType.ScannedObject,
                                                            latLon = objectWithoutAction.latLon,
                                                            geoObjectDetails = objectWithoutAction,
                                                            timeStamp = Clock.System.now(),
                                                        ),
                                                    )
                                                    objectAndUserHandler.updateAndLocateActiveObject(objectWithoutAction)
                                                }
                                            }

                                            is CodeLookupResult.ActionOnGeoObject -> {
                                                val objectWithAction = result.result
                                                // update LastKnownLocationStore with this scanned object
                                                lastKnownPositionStore.update(
                                                    LastKnownPosition(
                                                        type = PositionType.ScannedObject,
                                                        latLon = objectWithAction.latLon,
                                                        geoObjectDetails = objectWithAction,
                                                        timeStamp = Clock.System.now(),
                                                    ),
                                                )
                                                val action = objectWithAction.tags.actionIdMap[result.code]
                                                console.log(
                                                    "ActionId with Action!",
                                                    action,
                                                    objectWithAction.tags.toTypedArray(),
                                                )
                                                alertOverlayStore.notify(flowOf("${scannedCode.codeTech?.name?.uppercase()}: $scannedId"), 1)
                                                when (action) {
                                                    is Action.OpenPoll -> {
                                                        // show poll or results directly
                                                        objectAndUserHandler.showPollDirectly(objectWithAction to action.pollId)
                                                    }

                                                    is Action.VotePollOption -> {
                                                        // vote for option on poll directly
                                                        // TODO confirmation screen
                                                    }

                                                    is Action.OpenMap -> {
                                                        val position = objectWithAction.latLon
                                                        val zoomLevel = action.zoomLevel
                                                        val buildingId =
                                                            objectWithAction.tags.buildingId
                                                        val floorId = objectWithAction.tags.floorId
                                                        moveMapToPosition(position, zoomLevel, floorId, buildingId)
                                                    }

                                                    is Action.ScanToCreateTask -> {
                                                        val preAttachmentsStore by koinCtx.inject<PreAttachmentsStore>()
                                                        // prefill attachment
                                                        preAttachmentsStore.addPreAttachmentAndSave(
                                                            PreAttachment.PreGeoObject(
                                                                id = "new-${Id.next()}",
                                                                objectId = objectWithAction.id,
                                                                title = "Connected to",
                                                                rel = LinkRel.related,
                                                                htmlPreview = null,
                                                            ),
                                                        )
                                                        // prefill task fields
                                                        activeObjectStore.resetStore()
                                                        val taskTemplate =
                                                            (objectWithAction.attachments?.firstOrNull { it.id == action.attachmentId } as? Content.ScanToCreateTask)?.taskTemplate
                                                                ?: TaskTemplate("")
                                                        taskTemplate.title?.let {
                                                            activeObjectStore.map(GeoObjectDetails.L.title).update(it)
                                                        }
                                                        activeObjectStore.map(GeoObjectDetails.L.color)
                                                            .update(taskTemplate.color)
                                                        activeObjectStore.map(GeoObjectDetails.L.iconCategory)
                                                            .update(taskTemplate.iconCategory)
                                                        activeObjectStore.map(GeoObjectDetails.L.shape)
                                                            .update(taskTemplate.shape)
                                                        activeObjectStore.map(GeoObjectDetails.L.atTime)
                                                            .update(taskTemplate.atTimeInstant)
                                                        activeObjectStore.map(GeoObjectDetails.L.assignedTo)
                                                            .update(taskTemplate.assignedTo)
                                                        activeObjectStore.map(GeoObjectDetails.L.keywords)
                                                            .update(taskTemplate.keywords)
                                                        activeObjectStore.map(GeoObjectDetails.L.latLon)
                                                            .update(taskTemplate.latLon ?: objectWithAction.latLon)
                                                        taskTemplate.fieldValueTags?.let {
                                                            activeObjectStore.readFieldValues(
                                                                it,
                                                            )
                                                        }
                                                        taskTemplate.textAttachment?.let {
                                                            preAttachmentsStore.addPreAttachmentAndSave(
                                                                PreAttachment.PreMarkdown(
                                                                    id = "new-${Id.next()}",
                                                                    text = it,
                                                                    title = "Task details", // TODO scan-to: Use description Title field if its there
                                                                    htmlPreview = null,
                                                                ),
                                                            )
                                                        }
                                                        activeObjectStore.focusThisObject()
//                                                    routerStore.addOrReplaceRoute(Pages.Map.route + mapOf("add" to ObjectType.Task.name))
                                                        routerStore.validateInternalRoute(Pages.Map.route + mapOf("add" to ObjectType.Task.name))
                                                    }

                                                    is Action.OpenWeblink -> {
                                                        objectWithAction.attachments?.firstOrNull { content ->
                                                            content.id == action.weblinkId && content is Content.WebLink
                                                        }.let { attachment ->
                                                            val weblink = (attachment as Content.WebLink).href
                                                            console.log("Open web link: $weblink", action)
                                                            window.open(weblink, "_blank")
                                                        }
                                                        routerStore.validateExternalRoute(
                                                            mapOf(
                                                                "page" to Pages.Map.name,
                                                                "id" to (objectWithAction.tags.getUniqueTag(ObjectTags.ExternalId)
                                                                    ?: objectWithAction.id),
                                                            ),
                                                        )
                                                    }

                                                    is Action.UnknownActionType -> {
                                                        console.warn("Action is unknown.", action)
                                                    }

                                                    else -> {
                                                        console.warn("Action is not supported yet.", action)
                                                        if (checkFromScan) {
                                                            objectAndUserHandler.updateAndLocateActiveObject(objectWithAction)
                                                        }
                                                    }
                                                }
                                            }

                                            is CodeLookupResult.User -> {
                                                console.log("Code is connected to user", result.result.name)
                                                activeUserStore.fetchPublicProfile(result.result.userId)
                                                routerStore.resetHistory()
                                                routerStore.validateInternalRoute(Pages.UserProfile.route)
                                            }

                                            is CodeLookupResult.NotFound -> {
                                                console.log("Object, Action or User from scanned code: ${result.code} not found")
                                                alertOverlayStore.notify(flowOf("${scannedCode.codeTech?.name?.uppercase()}: $scannedId"))
                                                update(scannedCode)
                                                route?.minus("o")?.minus("id")?.plus(Cards.CreateTrackedObject.route)
                                                    ?.let { routerStore.validateInternalRoute(it) } ?: run {
                                                    routerStore.validateInternalRoute(
                                                        router.current + Pages.Map.route + Cards.CreateTrackedObject.route,
                                                    )
                                                }
                                            }

                                            else -> {
                                                // TODO
                                                console.log("Code not yet checked.")
                                            }
                                        }
                                    },
                                    errorMessage = null,
                                    processError = { error ->
                                        // TODO
                                    },
                                )
                            }
                        }
                    }

                    else -> {

                    }
                }
            } else if (userId != null) {
                console.log("Try open user profile via userId from route:", userId)
                objectAndUserHandler.updateAndLocateActiveUserById(userId)
            }
            // TODO specifically handle scanned urls that are not having a readable format
            else if (fallback != null) {
                alertOverlayStore.warnNotify(
                    flowOf("Not a valid URL or CODE: ${scannedCode.codeTech?.name?.uppercase()}: $fallback \n"),
                )

                update(scannedCode)
                route?.minus("o")?.minus("id")?.plus(Cards.CreateTrackedObject.route)
                    ?.let { routerStore.validateInternalRoute(it) }
            } else {
                console.log("Scanned id or group is null. Id: $scannedId, group: $groupId")
            }
        }
    }

    private fun moveMapToPosition(
        position: LatLon,
        zoomLevel: Int?,
        floorId: String?,
        buildingId: String?,
        speed: Double = 1.5,
        curve: Double = 1.42,
    ) {
        withKoin {
            val maplibreMap = get<MaplibreMap>()
            val router = get<RouterStore>()

            maplibreMap.removeAllActiveObjectOverrides()
            // select correct floor
            if (floorId != null && buildingId != null) {
                console.error("setting active floor and building")
                val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
                val activeBuildingOverrideStore: ActiveBuildingOverrideStore by koinCtx.inject()

                activeBuildingOverrideStore.update(BuildingOverride(buildingId, floorId))
                currentBuildingsStore.setFloorForBuilding(Pair(buildingId, floorId))
            }
            maplibreMap.flyTo(
                position,
                zoomLevel?.toDouble() ?: 22.0,
                curve = curve, speed = speed,
            )
            router.goToMap()
        }
    }

    val checkCodeActionFromScan = handle<ScannedCode> { _, scannedCode ->
        checkCodeActionFromScanOrUrl(scannedCode = scannedCode, route = null)
        scannedCode
    }

    val updateLocation = handle { current ->
        val geoPositionStore: GeoPositionStore by koinCtx.inject()
        val geoPositionService: GeoPositionService by koinCtx.inject()
        val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
        val activeObjectStore: ActiveObjectStore by koinCtx.inject()
        val position = activeObjectStore.map(GeoObjectDetails.L.latLon)
        val maplibreMap: MaplibreMap by koinCtx.inject()
        val busyStore by koinCtx.inject<BusyStore>()
        val currentBuildingsStore by koinCtx.inject<CurrentBuildingsStore>()
        val mapLayersStore: MapLayersStore by koinCtx.inject()
        val formationClient: FormationClient by koinCtx.inject()
        val analyticsService = koinCtx.get<AnalyticsService>()

        //        geoPositionService.getPosition()
        geoPositionService.getActiveWatchIdOrNew()

        val currentPosition = geoPositionStore.current?.coords?.let { LatLon(it.latitude, it.longitude) }

        if (current.extOrIntObjIdOrActionId != null && currentPosition != null) {
            maplibreMap.panTo(currentPosition)
            val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId ?: ""

            busyStore.handleApiCall(
                supplier = suspend {
                    val lookupResult = formationClient.lookupCode(
                        groupId = groupId,
                        code = current.extOrIntObjIdOrActionId,
                    ).getOrThrow()
                    when (lookupResult) {
                        is CodeLookupResult.ActionOnGeoObject -> error("cannot update location of object")
                        is CodeLookupResult.GeoObject -> {
                            lookupResult.result.id

                            formationClient.applyObjectChanges(
                                ObjectChanges(
                                    lookupResult.result.id,
                                    listOf(
                                        UpdatePointLocation(
                                            position = currentPosition,
                                            zoneId = currentBuildingsStore.pointWithinFloor(currentPosition),
                                        ),
                                    ),
                                ),
                            )
                        }

                        is CodeLookupResult.NoAccess -> error("cannot update location of object to which you have no access")
                        is CodeLookupResult.NotFound -> error("cannot update location of object that does not exist")
                        is CodeLookupResult.User -> error("cannot update location of user this way")
                    }
                },
                successMessage = translation[TL.AlertNotifications.SCANNED_OBJECT_POSITION_UPDATED],
                processResult = { res ->
                    analyticsService.createEvent(AnalyticsCategory.ObjectTracking) {
                        this.update(
                            target = current.extOrIntObjIdOrActionId,
                            location = currentPosition
                        )
                    }
                    position.update(currentPosition)
                    // make sure the updated object gets directly inserted into the map layers
                    activeObjectStore.setActiveObjectCopyResult(res.first().copy(latLon = currentPosition), true)
                    mapLayersStore.setUpdatedHit(res.first().copy(latLon = currentPosition))
                },
                errorMessage = translation[TL.AlertNotifications.SCANNED_OBJECT_POSITION_UPDATE_FAILED],
                processError = { t ->
                    console.error(t)
                },
                withBusyState = true,
                busyStateMessage = translation[TL.AlertNotifications.SCANNING_OBJECT],
            )
        } else {
            alertOverlayStore.show(
                Overlay.NotificationToast(
                    notificationType = NotificationType.Alert,
                    durationSeconds = 5,
                    text = translation[TL.AlertNotifications.NO_POSITION_FOUND],
                    bgColor = FormationColors.GreenActive.color,
                ),
            )
        }
        current
    }

    val reset = handle {
        update(ScannedCode(extOrIntObjIdOrActionId = null, fallback = null, codeTech = null))
        ScannedCode(extOrIntObjIdOrActionId = null, fallback = null, codeTech = null)
    }

    private val resetIfEmpty = handle<ScannedCode> { _, data ->
        if (data.extOrIntObjIdOrActionId.isNullOrEmpty() && data.fallback.isNullOrEmpty()) {
            ScannedCode(extOrIntObjIdOrActionId = null)
        } else data
    }

    init {
        data handledBy resetIfEmpty
    }
}
