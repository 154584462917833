package data.users.profile

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.users.FullUserProfile
import apiclient.users.UserChange
import apiclient.users.applyUserChange
import apiclient.users.featureFlags
import apiclient.users.toPublicProfile
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.views.attachments.ImageFileDataStore
import data.users.UserListStore
import data.users.handlerScope
import dev.fritz2.core.Id
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import io.ktor.util.*
import koin.koinCtx
import kotlin.coroutines.EmptyCoroutineContext
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import maplibreGL.MaplibreMap
import model.MyProfile
import model.NotificationType
import model.Overlay
import overlays.AlertOverlayStore
import overlays.BusyStore
import qrcodegeneration.toSvgQrCode
import services.UserService
import theme.FormationColors
import utils.graphqlScope
import utils.loadImageData

class MyProfileStore : RootStore<MyProfile>(
    initialData = MyProfile(),
    job = Job(),
) {
    private val userService: UserService by koinCtx.inject()
    private val userListStore: UserListStore by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()
    private val imageFileDataStore: ImageFileDataStore by koinCtx.inject()
    private val formationClient by koinCtx.inject<FormationClient>()
    private val busyStore: BusyStore by koinCtx.inject()

    val myVcardQrCodeStore = storeOf<String?>(null, job)
    val myVcardStore = storeOf<String?>(null, job)

    private fun MyProfile.vCard(photoBytes: ByteArray? = null): String = utils.vCard(
        firstName, lastName, jobTitle, emails, phone, photoBytes,
    )

    private val generateMyVcardQRCode = handle { old ->
        handlerScope.launch {
            val vcardWithPhoto = current.vCard(
                current.profilePhoto?.thumbNail?.let {
                    it as Content.Image
                    loadImageData(it.href)
                },
            )
//            console.log("VCARD with photo for download", vcardWithPhoto)
            myVcardStore.update(vcardWithPhoto)
            val vcardLite = current.vCard()
//            console.log("VCARD lite for qr", vcardLite)
            val svg = toSvgQrCode(vcardLite)
            myVcardQrCodeStore.update("data:image/svg+xml;base64,${svg.encodeBase64()}")
        }
        old
    }

    val setUserProfile = handle<FullUserProfile> { current, userProfile ->
        current.copy(
            userId = userProfile.userId,
            firstName = userProfile.firstName ?: "",
            lastName = userProfile.lastName ?: "",
            phone = userProfile.phoneNumbers.joinToString(),
            emails = userProfile.emailAddresses.joinToString(),
            jobTitle = userProfile.jobTitle ?: "",
            preferences = userProfile.preferences,
            profilePhoto = userProfile.profilePhoto,
            featureFlags = userProfile.featureFlags,
            keywords = userProfile.keywords,
        )
    }

    val fetchMyProfile = handle { current ->
        CoroutineScope(EmptyCoroutineContext).launch {
            CoroutineName("get-user-profile-${Id.next()}")
            val maplibreMap: MaplibreMap by koinCtx.inject()
            val activeObjectStore: ActiveObjectStore by koinCtx.inject()
            val apiUser = apiUserStore.current.apiUser
            if (apiUser != null) {
                if (apiUser.isAnonymous) {
                    update(MyProfile("anonymous", "Anonymous", "User"))
                } else {
                    val profileResult = userService.getFullUserProfileById(apiUserStore.current.userId)
                    profileResult.fold(
                        { userProfile ->
                            setUserProfile(userProfile)
                        },
                        {
                            update(current.copy())
                        },
                    )
                }
                maplibreMap.once(type = "click", fn = activeObjectStore::resetOnMapClick, fnId = "resetOnMapClick")
            }
            imageFileDataStore.reset()
        }
        current
    }

    val reset = handle {
        console.log("Reset MyProfileStore")
        update(MyProfile())
        MyProfile()
    }

    val removeKeyword = handle<String> { current, tag ->
        busyStore.handleApiCall(
            supplier = {
                formationClient.applyUserChange(current.userId, UserChange.RemoveKeyWords(listOf(tag)))
            },
            processResult = { newProfile ->
                setUserProfile(newProfile)
            },
        )
        current
    }

    val addKeyword = handle<String> { current, tag ->
        busyStore.handleApiCall(
            supplier = {
                formationClient.applyUserChange(current.userId, UserChange.AddKeyWords(listOf(tag)))
            },
            processResult = { newProfile ->
                setUserProfile(newProfile)
            },
        )
        current
    }

    val updateMyProfile = handle { current ->
        //TODO validation here
        if (current.firstName.isBlank()) {
            alertOverlayStore.show(
                Overlay.NotificationToast(
                    notificationType = NotificationType.Alert,
                    durationSeconds = 4,
                    text = translation[TL.AlertNotifications.USER_PROFILE_FIRST_NAME_BLANK],
                    bgColor = FormationColors.RedError.color,
                ),
            )
            fetchMyProfile()
            return@handle current
        }

        val phoneNumbers = if (current.phone.ifBlank { null } == null) null else listOf(current.phone)
        val jobTitle = current.jobTitle.ifBlank { null }
        graphqlScope.launch {
            CoroutineName("update-user-profile-${Id.next()}")
            val updateUserDetailsResult = userService.updateUserDetails(
                userId = apiUserStore.current.userId,
                firstName = current.firstName,
                lastName = current.lastName.ifBlank { null },
                jobTitle = jobTitle,
                phoneNumbers = phoneNumbers, // TODO change this to add multiple phone numbers
            )
            updateUserDetailsResult.fold(
                {
                    alertOverlayStore.show(
                        Overlay.NotificationToast(
                            notificationType = NotificationType.Alert,
                            durationSeconds = 4,
                            text = translation[TL.AlertNotifications.USER_PROFILE_SUCCESSFULLY_UPDATED],
                            bgColor = FormationColors.GreenActive.color,
                        ),
                    )
                    userListStore.updateUserProfile(it.toPublicProfile())
                    apiUserStore.refreshUser()
                    update(current.copy())
                },
                {
                    //TODO display error in ticker here(should never reach this stage)
                    alertOverlayStore.show(
                        Overlay.NotificationToast(
                            notificationType = NotificationType.Alert,
                            durationSeconds = 4,
                            text = translation[TL.AlertNotifications.USER_PROFILE_UPDATE_FAILED],
                            bgColor = FormationColors.RedError.color,
                        ),
                    )
                    update(current.copy())
                },
            )
        }
        updateProfilePhoto()
        current
    }

    val updateProfilePhoto = handle { current ->
        val imgBytes = imageFileDataStore.current.prevBytes
        val imgType = imageFileDataStore.current.mimeType
        if (imgBytes != null && !imgType.isNullOrBlank()) {
            busyStore.handleApiCall(
                supplier = suspend {
                    userService.updateUserProfilePhoto(
                        userId = apiUserStore.current.userId,
                        imageBytes = imgBytes,
                        imageType = imgType,
                    )
                },
                successMessage = translation[TL.AlertNotifications.USER_PROFILE_SUCCESSFULLY_UPDATED],
                processResult = { profile: FullUserProfile ->
                    userListStore.updateUserProfile(profile.toPublicProfile())
                    update(current.copy(profilePhoto = profile.profilePhoto))
                },
                errorMessage = translation[TL.AlertNotifications.USER_PROFILE_UPDATE_FAILED],
                processError = { error: Throwable ->
                    console.log("Error updating profile photo", error)
                    update(current.copy())
                },
                withBusyState = true,
                busyStateMessage = flowOf("Updating profile photo..."), // TODO translate
            )
        } else {
            console.log("Img bytes or img type missing")
        }
        current
    }

    val deleteProfilePhoto = handle {
        busyStore.handleApiCall(
            supplier = suspend {
                userService.deleteUserProfilePhoto(
                    userId = apiUserStore.current.userId,
                )
            },
            successMessage = translation[TL.AlertNotifications.USER_PROFILE_SUCCESSFULLY_UPDATED], // TODO write messages
            processResult = { profile: FullUserProfile ->
                update(current.copy(profilePhoto = profile.profilePhoto))
            },
            errorMessage = translation[TL.AlertNotifications.USER_PROFILE_UPDATE_FAILED], // TODO write messages
            processError = { error: Throwable ->
                console.log("Error deleting profile photo", error)
                update(current.copy())
            },
            withBusyState = true,
            busyStateMessage = flowOf("Deleting profile photo..."), // TODO translate
        )
        current
    }

    init {
        apiUserStore.data.map { } handledBy fetchMyProfile
        data.map { } handledBy generateMyVcardQRCode
    }
}
