package overlays

import data.ObjectAndUserHandler
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.h1
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.src
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlin.math.max
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.plus
import localization.TL
import localization.Translation
import mainmenu.AppStateStore
import mainmenu.Pages
import mainmenu.RouterStore
import model.AppPhase
import model.ConfirmationType
import model.NotificationType
import model.Overlay
import model.SplashType
import network.NetworkState
import network.NetworkStateStore
import notifications.GlobalNotificationResultsStore
import org.w3c.dom.HTMLDivElement
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import utils.makeRGB
import utils.makeRGBA
import utils.px
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer
import webcomponents.genericButton
import webcomponents.genericSmallIconButton
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter
import webcomponents.userOrStateIcon

fun RenderContext.overlayNavigator() {
    val alertStore: AlertOverlayStore = koinCtx.get()
    val tickerStore: TickerOverlayStore = koinCtx.get()
    val splashStore: SplashOverlayStore = koinCtx.get()
    val confirmStore: ConfirmationOverlayStore = koinCtx.get()
    val router: MapRouter = koinCtx.get()
    val globalNotificationResultsStore: GlobalNotificationResultsStore = koinCtx.get()
    val networkStateStore by koinCtx.inject<NetworkStateStore>()

    val translation: Translation by koinCtx.inject()

    val notificationBarEnabled = router.data.combine(globalNotificationResultsStore.data.map { it.unreadNumber }) { route, unread ->
        if (route["page"] == Pages.Map.name && route["ws"] != null && route.keys.size <= 2) {
            unread > 0
        } else false
    }

    notificationBarEnabled.render { enabled ->
        if (enabled) {
            notificationBar()
        }
    }

    tickerStore.data.render { overlayState ->
        if (overlayState.enabled && overlayState.overlay is Overlay.NotificationToast) {
            notificationOverlay(overlayState.overlay)
        }
    }

    alertStore.data.render { overlayState ->
        if (overlayState.enabled && overlayState.overlay is Overlay.NotificationToast) {
            notificationOverlay(overlayState.overlay)
        }
    }

    splashStore.data.render { overlayState ->
        if (overlayState.enabled && overlayState.overlay is Overlay.SplashScreen) {
            when (overlayState.overlay.splashType) {
                SplashType.AppStartSplash -> appStartSplashScreen()
                SplashType.LoggingInSplash -> loggingInSplashScreen()
                SplashType.TokenSplash -> tokenExpired()
                else -> {}
            }
        }
    }

    confirmStore.data.render { overlayState ->
        if (overlayState.enabled && overlayState.overlay is Overlay.ConfirmationScreen) {
            when (overlayState.overlay.confirmationType) {
                ConfirmationType.Close -> confirmationClose(overlayState.overlay)
                ConfirmationType.AutoClose -> confirmationAutoClose(overlayState.overlay)
                ConfirmationType.UserAction -> confirmationUserAction(overlayState.overlay)
                ConfirmationType.AppVersionExpired -> appVersionExpired(overlayState.overlay)
                ConfirmationType.Generic -> confirmationGeneric(overlayState.overlay)
                else -> {}
            }
        }
    }

    networkStateStore.data.render { network ->
        when (network) {
            NetworkState.Offline -> {
                // Using notificationOverlay directly, to manage alert by networkStateStore, without specific duration
                notificationOverlay(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = null,
                        text = translation[TL.Network.YOU_ARE_OFFLINE_NETWORK_CONNECTION_REQUIRED],
                        bgColor = FormationColors.GrayDisabled.color,
                    ),
                )
                offline()
            }

            else -> {}
        }
    }

}

fun RenderContext.notificationOverlay(overlay: Overlay.NotificationToast?) {

    val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()

    div(
        {
            position { absolute { top { none }; horizontal { none } } }
            zIndex { "9000" }
            overlay?.bgColor?.let { background { color { it } } }
            overlay?.textColor?.let { color { it } }
            height { "50px" }
            width { full }
        },
    ) {
        flexBox(
            {
                height { full }
                width { full }
                justifyContent { center }
                alignItems { center }
            },
        ) {
            when (overlay?.notificationType) {
                NotificationType.Alert -> {
                    div(
                        {
                            textTransform { uppercase }
                            color { secondary.main }
                            justifyContent { center }
                            alignItems { center }
                            padding { small }
                        },
                    ) {
                        button(
                            {
                                width { full }
                                height { full }
                            },
                        ) {
                            overlay.text?.render { text ->
                                span(
                                    {
                                        fontSize { if (text.length > 40) small else normal }
                                    },
                                ) { +text }
                            }
                            with(clicks) {
                                overlay.geoObject?.let { obj -> this.map { obj } handledBy objectAndUserHandler.updateAndLocateObjectOrUserFromList }
                                overlay.primaryClickHandlers?.let { handlers ->
                                    handlers.forEach { handler ->
                                        this.map { Unit } handledBy handler
                                    }
                                }
                            }
                        }
                    }
                }

                NotificationType.Ticker -> {
                    overlay.text?.render { text ->
                        div(
                            {
                                position { absolute { } }
                                css("white-space: nowrap;")
//                        textTransform { uppercase }
                                color { secondary.main }
                                overflow { hidden }
                                justifyContent { center }
                                alignItems { center }
                                css(
                                    "animation: scroll ${max(text.length, 15)}s linear 0s infinite;\n" +
                                        "@keyframes scroll {\n" +
                                        "    from {\n" +
                                        "        transform: translate(calc(50vw + 50%),0)\n" +
                                        "    }\n" +
                                        "\n" +
                                        "    to {\n" +
                                        "       transform: translate(calc(-50vw - 50%),0)\n" +
                                        "    }\n" +
                                        "}",
                                )
                            },
                        ) {
                            button(
                                {
                                    width { full }
                                    height { full }
                                },
                            ) {
                                span(
                                    {
                                        fontSize { if (text.length > 40) small else normal }
                                    },
                                ) { +text }

                                with(clicks) {
                                    overlay.geoObject?.let { obj -> this.map { obj } handledBy objectAndUserHandler.updateAndLocateObjectOrUserFromList }
                                    overlay.primaryClickHandlers?.let { handlers ->
                                        handlers.forEach { handler ->
                                            this.map { Unit } handledBy handler
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                else -> {}
            }
        }
    }
}


fun RenderContext.fullScreenOverlay(content: (HtmlTag<HTMLDivElement>.() -> Unit)?) {
    div(
        {
            position { fixed { horizontal { none }; vertical { none } } }
            zIndex { "1999" }
        },
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.appStartSplashScreen() {
    val router: MapRouter by koinCtx.inject()

    fullScreenOverlay {
        flexBox(
            {
                width { full }
                height { full }
                background { color { secondary.main } }
                direction { column }
                justifyContent { spaceBetween }
                alignItems { center }
                paddings { bottom { larger } }
                overflow { hidden }
            },
        ) {
            if (router.current["ws"] == "formation4ukraine") {
                flexBox({ flex { grow { "1" } } }) { }
                img(
                    {
                        display { flex }
                        minHeight { "150px" }
                        maxHeight { "400px" }
                        flex { grow { "1" } }
                        paddings { horizontal { giant } }
                    },
                ) {
                    src("assets/images/logo-formation4ukraine.svg")
                }
                flexBox({ flex { grow { "1" } } }) { }
            } else {
                flexBox({ flex { grow { "1" } } }) { }

                img(
                    {
                        display { flex }
                        minHeight { "150px" }
                        maxHeight { "150px" }
                        flex { grow { "1" } }
                        paddings { horizontal { giant } }
                    },
                ) {
                    src("assets/images/logo-blue_2.svg")
                }

                img(
                    {
                        display { flex }
                        width { full }
                        maxWidth { "500px" }
                        css(
                            "-webkit-transform: scaleY(-1);\n" +
                                "  transform: scaleY(-1);",
                        )
                    },
                ) {
                    src("assets/images/murmurations-corners-01.png")
                }

                flexBox({ flex { grow { "1" } } }) { }
//            span({ flex { grow { "1" } } }) { +"Version 0.1" }
            }

        }
    }
}

fun RenderContext.loggingInSplashScreen() {

    val translation: Translation by koinCtx.inject()

    fullScreenOverlay {
        flexBox(
            {
                width { full }
                height { full }
                background { color { secondary.main } }
                direction { column }
                justifyContent { spaceBetween }
                alignItems { center }
                paddings { bottom { larger } }
                overflow { hidden }
            },
        ) {
            flexBox({ flex { grow { "3" } } }) { }
            h1(
                {
                    fontWeight { lighter }
                },
            ) { translation[TL.LoginSplash.LOGGING_IN].renderText(into = this) }
            img(
                {
                    display { flex }
                    width { full }
                    maxWidth { "500px" }
                    css(
                        "-webkit-transform: scale(-1);\n" +
                            "  transform: scale(-1);",
                    )
                },
            ) {
                src("assets/images/murmurations-corners-01.png")
            }
            flexBox({ flex { grow { "1" } } }) { }
//            span { +"Version 0.1" }
        }
    }
}

fun RenderContext.notificationBar() {

    val translation: Translation by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    val notifications = globalNotificationResultsStore.data.map { it.unreadNumber }
    div(
        {
            position { fixed { top { none }; horizontal { none } } }
            zIndex { "1950" }
            height { "50px" }
            width { full }
        },
    ) {
        flexBox(
            {
                height { full }
                width { full }
                textTransform { uppercase }
                color { FormationColors.MarkerYou.color }
                background { color { FormationColors.White.color } }
                justifyContent { spaceBetween }
                alignItems { center }
                padding { small }
            },
        ) {
            div {
                icon(
                    {
                        paddings { right { small } }
                        size { giant }
                    },
                ) { fromTheme { FormationIcons.NotificationsOn.icon } }
                span(
                    {
                        fontWeight { bold }
                    },
                ) { notifications.map { "$it ${translation.getString(TL.Notifications.NOTIFICATIONS, mapOf("number" to it))}" }.renderText(into = this) }
            }

            genericButton(
                title = translation[TL.CardMapLayerSelection.SEE_ALL],
                width = { maxContent },
                styleFlow = flowOf {
                    border {
                        width(FormationDefault.formationStyles.borderWidth)
                        color { FormationColors.MarkerYou.color }
                    }
                    fontSize { small }
                    fontWeight { bold }
                    radius(FormationDefault.formationStyles.inputRadius)
                },
                styleType = { info },
                routingMap = mapOf("page" to Pages.NotificationCenter.name),
                value = Unit,
            )
        }
    }
}


fun RenderContext.tokenExpired() {
    fullScreenOverlay {
        flexBox(
            {
                width { full }
                height { full }
                direction { column }
                justifyContent { center }
                alignItems { center }
                padding { larger }
                background { color { secondary.main } }
            },
        ) {
            cardTitle(title = flowOf("Session expired."), icon = { FormationIcons.Time.icon })
            cardTitle(title = flowOf("Logging out..."))
        }
    }
}

val refreshPage: SimpleHandler<Unit> = SimpleHandler { flow, job ->
    flow.onEach {
        window.location.reload()
    }.launchIn(MainScope() + job)
}

fun RenderContext.offline() {

    val translation: Translation by koinCtx.inject()
    val appStateStore: AppStateStore by koinCtx.inject()

    appStateStore.data.render { loginState ->
        if (loginState.appPhase == AppPhase.LoggedIn || loginState.appPhase == AppPhase.LoggedInAnonymous) {
            fullPageConfirmation {
                fullPageConfirmationContainer(
                    width = { maxContent },
                ) {
                    stackUp(
                        {
                            width { full }
                            justifyContent { spaceBetween }
                            alignItems { center }
                        },
                    ) {
                        spacing { small }
                        items {
                            span(
                                {
                                    textAlign { center }
                                    fontSize { normal }
                                    fontWeight { bold }
                                    css(
                                        """
                                    animation: blink 3s infinite ease-in;
                                    @keyframes blink {
                                      0% { opacity: 0; }
                                      25% { opacity: 1; }
                                      100% { opacity: 0; }
                                    }
                                """.trimIndent(),
                                    )
                                },
                            ) {
                                translation[TL.Network.TRYING_TO_RECONNECT].renderText(into = this)
                            }
                            dotSpinner()
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.dotSpinner(pixelSize: Int = 10, color: FormationColors? = null) {
    val spinnerId = Id.next()
    val replaceRGBColor = color?.color?.let { makeRGB(it) } ?: makeRGB(FormationColors.GreenBright.color)
    val replaceRGBAColor = color?.color?.let { makeRGBA(it, 0.0) } ?: makeRGBA(FormationColors.GreenBright.color, 0.0)
    flexBox(
        {
//        padding { (pixelSize*2).px() }
            width { (pixelSize * 5).px() }
            height { (pixelSize * 5).px() }
            alignItems { center }
            justifyContent { center }
        },
    ) {
        div(
            {
                width { pixelSize.px() }
                height { pixelSize.px() }
                radius { (pixelSize / 2).px() }
                color?.let { color { it.color } }
                css(
                    """
                    box-shadow: 0 ${(pixelSize * -1.8).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.8).px()} 0 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBAColor, 0 ${(pixelSize * 1.8).px()} 0 0 $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBAColor, ${(pixelSize * -1.8).px()} 0 0 0 $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBAColor;
                    animation: dotSpin-$spinnerId 1.5s infinite linear;
                    @keyframes dotSpin-$spinnerId {
                      0%,
                      100% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.8).px()} 0 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, 0 ${(pixelSize * 1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor;
                      }
                      12.5% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.8).px()} 0 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBColor, 0 ${(pixelSize * 1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor;
                      }
                      25% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.8).px()} 0 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBColor, 0 ${(pixelSize * 1.8).px()} 0 0 $replaceRGBColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor;
                      }
                      37.5% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBColor, 0 ${(pixelSize * 1.8).px()} 0 0 $replaceRGBColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBColor, ${(pixelSize * -1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor;
                      }
                      50% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, 0 ${(pixelSize * 1.8).px()} 0 0 $replaceRGBColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBColor, ${(pixelSize * -1.8).px()} 0 0 0 $replaceRGBColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor;
                      }
                      62.5% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, 0 ${(pixelSize * 1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 0 $replaceRGBColor, ${(pixelSize * -1.8).px()} 0 0 0 $replaceRGBColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBColor;
                      }
                      75% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, 0 ${(pixelSize * 1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.8).px()} 0 0 0 $replaceRGBColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBColor;
                      }
                      87.5% {
                        box-shadow: 0 ${(pixelSize * -1.8).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBColor, ${(pixelSize * 1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * 1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, 0 ${(pixelSize * 1.8).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * 1.272984).px()} 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.8).px()} 0 0 ${(pixelSize / -2).px()} $replaceRGBAColor, ${(pixelSize * -1.272984).px()} ${(pixelSize * -1.272984).px()} 0 0 $replaceRGBColor;
                      }
                    }
                """.trimIndent(),
                )
            },
        ) { }
    }
}

fun RenderContext.confirmationClose(overlay: Overlay.ConfirmationScreen) {

    val translation: Translation by koinCtx.inject()
    val confirmationOverlayStore: ConfirmationOverlayStore by koinCtx.inject()

    fullPageConfirmation {
        fullPageConfirmationContainer {
            baseLayout(
                expandable = false,
                header = null,
                content = {
                    contentScrollBox {
                        stackUp(
                            {
                                width { full }
                                height { full }
                                alignItems { center }
                                justifyContent { center }
                                padding { small }
                                overflowY { auto }
                                textAlign { center }
                            },
                        ) {
                            spacing { huge }
                            items {
                                overlay.icon?.let { i ->
                                    userOrStateIcon(
                                        pixelSize = 50.0,
                                        shadow = false,
                                        icon = { i },
                                        iconBackground = { overlay.bgColor },
                                        textColor = { overlay.textColor },
                                    )
                                }
                                overlay.text?.let {
                                    cardSubtitle(title = it)
                                }
                            }
                        }
                    }
                },
                footer = {
                    oneButtonFooter(
                        title = overlay.primaryActionName ?: translation[TL.General.CLOSE],
                        styleParams = primaryButtonStyleParams,
                        value = Unit,
                        clickHandlers = (overlay.primaryClickHandlers ?: listOf()) + listOf(confirmationOverlayStore.reset),
                        analyticsEventProvider = null,
                    )
                },
            )
        }
    }
}

fun RenderContext.confirmationAutoClose(overlay: Overlay.ConfirmationScreen) {

    fullPageConfirmation {
        fullPageConfirmationContainer {
            baseLayout(
                expandable = false,
                header = null,
                content = {
                    contentScrollBox {
                        stackUp(
                            {
                                width { full }
                                height { full }
                                alignItems { center }
                                justifyContent { center }
                                padding { small }
                                overflowY { auto }
                                textAlign { center }
                            },
                        ) {
                            spacing { huge }
                            items {
                                overlay.icon?.let { i ->
                                    userOrStateIcon(
                                        pixelSize = 50.0,
                                        shadow = false,
                                        icon = { i },
                                        iconBackground = { overlay.bgColor },
                                        textColor = { overlay.textColor },
                                    )
                                }
                                overlay.text?.let {
                                    cardTitle(
                                        title = it,
                                        maxLines = 2,
                                    )
                                }
                            }
                        }
                    }
                },
                footer = null,
            )
        }
    }
}

fun RenderContext.confirmationUserAction(overlay: Overlay.ConfirmationScreen) {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val confirmationOverlayStore: ConfirmationOverlayStore by koinCtx.inject()

    fullPageConfirmation {
        fullPageConfirmationContainer {
            baseLayout(
                expandable = false,
                header = null,
                content = {
                    contentScrollBox {
                        stackUp(
                            {
                                width { full }
                                height { full }
                                alignItems { center }
                                justifyContent { center }
                                padding { small }
                                overflowY { auto }
                                textAlign { center }
                            },
                        ) {
                            spacing { huge }
                            items {
                                overlay.icon?.let { i ->
                                    userOrStateIcon(
                                        pixelSize = 50.0,
                                        shadow = false,
                                        icon = { i },
                                        iconBackground = { overlay.bgColor },
                                        textColor = { overlay.textColor },
                                    )
                                }
                                overlay.text?.let {
                                    cardTitle(
                                        title = it,
                                        maxLines = 2,
                                    )
                                }
                            }
                        }
                    }
                },
                footer = {
                    if (overlay.secondaryActionName != null) {
                        twoButtonFooter(
                            primaryTitle = overlay.primaryActionName ?: translation[TL.General.CLOSE],
                            primaryStyleParams = primaryButtonStyleParams,
                            primaryValue = Unit,
                            primaryClickHandlers = (overlay.primaryClickHandlers ?: listOf()) + listOf(confirmationOverlayStore.reset),
                            primaryAnalyticsEventProvider = null,
                            secondaryTitle = overlay.secondaryActionName,
                            secondaryStyleParams = secondaryButtonStyleParams,
                            secondaryClickHandlers = overlay.secondaryClickHandlers,
                            secondaryAnalyticsEventProvider = null,
                        )
                    } else {
                        oneButtonFooter(
                            title = translation[TL.General.CLOSE],
                            styleParams = primaryButtonStyleParams,
                            value = Unit,
                            clickHandlers = (overlay.primaryClickHandlers ?: listOf()) + listOf(confirmationOverlayStore.reset),
                            analyticsEventProvider = null,
                        )
                    }
                },
            )
        }
    }
}

fun RenderContext.confirmationGeneric(overlay: Overlay.ConfirmationScreen) {
    fullPageConfirmation {
        overlay.content?.invoke(this)
    }
}

fun RenderContext.appVersionExpired(overlay: Overlay.ConfirmationScreen) {
    val translation: Translation by koinCtx.inject()
    val confirmationOverlayStore: ConfirmationOverlayStore by koinCtx.inject()

    fullPageConfirmation {
        fullPageConfirmationContainer {
            baseLayout(
                expandable = false,
                header = null,
                content = {
                    contentScrollBox {
                        stackUp(
                            {
                                width { full }
                                height { full }
                                alignItems { center }
                                justifyContent { center }
                                padding { small }
                                overflowY { auto }
                                textAlign { center }
                            },
                        ) {
                            spacing { huge }
                            items {
                                userOrStateIcon(
                                    pixelSize = 50.0,
                                    shadow = false,
                                    icon = { FormationIcons.Update.icon },
                                    iconBackground = { overlay.bgColor },
                                    textColor = { overlay.textColor },
                                )

//                                if (isMobileOrTabletBrowser()) {
//                                    cardSubtitle(title = translation[TL.AppVersion.EXPIRED_UPDATE_MESSAGE])
//                                } else {
//                                    cardSubtitle(title = translation[TL.AppVersion.EXPIRED_REFRESH_MESSAGE])
//                                }
                                cardSubtitle(title = translation[TL.AppVersion.EXPIRED_REFRESH_MESSAGE])

                                div(
                                    {
                                        margins { vertical { huge } }
                                    },
                                ) {
//                                    if (isMobileOrTabletBrowser()) {
//                                        genericSmallIconButton(
//                                            title = translation[TL.AppVersion.GO_TO_PLAYSTORE],
//                                            icon = { FormationUIIcons.ExternalLink.icon },
//                                            iconPosition = Position.Left,
//                                            hrefOrValue = if (window["cordova"] != null) "market://details?id=com.tryformation" else "https://play.google.com/store/apps/details?id=com.tryformation",
//                                            isLink = true,
//                                        )
//                                    } else {
//                                        genericSmallIconButton(
//                                            title = translation[TL.AppVersion.REFRESH],
//                                            icon = { refresh },
//                                            iconPosition = Position.Left,
//                                            hrefOrValue = Unit,
//                                            clickHandlers = listOf(refreshPage),
//                                        )
//                                    }
                                    genericSmallIconButton(
                                        title = translation[TL.AppVersion.REFRESH],
                                        icon = { refresh },
                                        iconPosition = Position.Left,
                                        hrefOrValue = Unit,
                                        clickHandlers = listOf(refreshPage),
                                    )
                                }
                            }
                        }
                    }
                },
                footer = {
                    if (overlay.primaryActionName != null && overlay.secondaryActionName != null) {
                        twoButtonFooter(
                            primaryTitle = overlay.primaryActionName,
                            primaryStyleParams = primaryButtonStyleParams,
                            primaryValue = Unit,
                            primaryClickHandlers = (overlay.primaryClickHandlers ?: listOf()) + listOf(confirmationOverlayStore.reset),
                            primaryAnalyticsEventProvider = null,
                            secondaryTitle = overlay.secondaryActionName,
                            secondaryStyleParams = secondaryButtonStyleParams,
                            secondaryClickHandlers = overlay.secondaryClickHandlers,
                            secondaryAnalyticsEventProvider = null,
                        )
                    } else {
                        oneButtonFooter(
                            title = translation[TL.General.CLOSE],
                            styleParams = primaryButtonStyleParams,
                            value = Unit,
                            clickHandlers = (overlay.primaryClickHandlers ?: listOf()) + listOf(confirmationOverlayStore.reset),
                            analyticsEventProvider = null,
                        )
                    }
                },
            )
        }
    }
}
