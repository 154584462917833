package map.bottombar

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.users.UserFeatureFlag
import apiclient.websocket.MessageToServer
import auth.FeatureFlagStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.title
import dev.fritz2.routing.MapRouter
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.params.rgba
import dev.fritz2.styling.params.shadow
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import twcomponents.doIfUserFeatureFlagEnabled


fun RenderContext.bottomBarCore() {

    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()

    div("w-full flex flex-row items-center justify-between") {
        // MENU BUTTON
        bottomBarButton(
            bottomBarPage = Pages.Menu,
            icon = { FormationIcons.MenuAlt.icon },
            iconSize = { giant },
            title = translation[TL.BottomBar.MENU],
//            routingMap = if (!router.current.isBasePage(Pages.Menu)) {
//                Pages.Menu.route
//            } else Pages.Map.route,
            routingMap = Pages.Menu.route,
            prefix = "menu",
            clickHandlers = listOf(routerStore.addToMapClickListener),
            analyticsEventProvider = AnalyticsCategory.MenuButton.click(),
        )

        featureFlagStore.data.render { features ->
            doIfUserFeatureFlagEnabled(UserFeatureFlag.EnableDevelopmentFeatures) {
                // HUB BUTTON
                bottomBarButton(
                    bottomBarPage = Pages.Hub,
                    icon = { FormationIcons.Hub.icon },
                    iconSize = { giant },
                    title = translation[TL.BottomBar.HUB],
//                    routingMap = if (!router.current.isBasePage(Pages.Hub)) {
//                        Pages.Hub.route
//                    } else Pages.Map.route,
                    routingMap = Pages.Hub.route,
                    prefix = "hub",
                    clickHandlers = listOf(routerStore.addToMapClickListener),
                    analyticsEventProvider = AnalyticsCategory.HubButton.click(),
                )
            }
        }
        // ADD BUTTON
//            flexBox(prefix = "AddButtonHolder", styling = {
//                position { fixed { bottom { tiny } } }
////                    position(sm = { fixed { left { "50%" } } }, md = { fixed { left { "180px" } } })
////                    position(sm = { fixed { left { "20%" } } }, md = { fixed { left { "60px" } } })
//                position { fixed { left { "40px" } } }
//                css("transform: translate(-50%, -150%);")
//                display { flex }
//                justifyContent { center }
//                alignItems { center }
//                zIndex { "1020" }
//            }) {
//                flexBox({
//                    direction { column }
//                    justifyContent { center }
//                    alignItems { center }
//                    wrap { nowrap }
//                }) {
//                    circleIconButton(
//                        iconFlow = flowOf { add },
//                        size = { "46px" },
//                        iconSize = { giant },
//                        styleFlow = flowOf(addCircleButtonStyle),
//                        tooltipText = translation[TL.BottomBar.ADD_TOOLTIP],
//                        disabled = features[Features.DisablePlusButton] == true,
//                        value = Unit,
//                        clickHandlers = listOf(activeObjectStore.resetStore),
//                        addOrReplaceRoute = mapOf("card" to Cards.Select.name),
//                        analyticsEventProvider = AnalyticsCategory.AddContentButton.click()
//                    )
////                    span({
////                        fontSize { smaller }
////                        fontWeight { lighter }
////                        margins {
////                            top { smaller }
////                        }
////                    }) { translation[TL.BottomBar.ADD].renderText(into = this) }
//                }
//            }
        // MAP BUTTON
        bottomBarButton(
            bottomBarPage = Pages.Map,
            icon = { FormationIcons.Map.icon },
            iconSize = { giant },
            title = translation[TL.BottomBar.MAP],
            routingMap = Pages.Map.route,
            prefix = "map",
            analyticsEventProvider = AnalyticsCategory.MapButton.click(),
        )

        // SCAN BUTTON
        bottomBarButton(
            bottomBarPage = Pages.Scan,
            icon = { FormationIcons.Scan.icon },
            iconSize = { giant },
            title = translation[TL.BottomBar.SCAN],
//            routingMap = if (!router.current.isBasePage(Pages.Scan)) {
//                Pages.Scan.route
//            } else Pages.Map.route,
            routingMap = Pages.Scan.route,
            prefix = "scan",
            analyticsEventProvider = AnalyticsCategory.ScanButton.click(),
        )

        // SEARCH BUTTON
        bottomBarButton(
            bottomBarPage = Pages.Search,
            icon = { FormationIcons.SearchAlt.icon },
            iconSize = { giant },
            title = translation[TL.BottomBar.SEARCH],
//            routingMap = if (!router.current.isBasePage(Pages.Search)) {
//                Pages.Search.route
//            } else Pages.Map.route,
            routingMap = Pages.Search.route,
            prefix = "search",
            analyticsEventProvider = AnalyticsCategory.SearchButton.click(),
        )
    }
}

fun RenderContext.standAloneBottomBar() {
    val router by koinCtx.inject<MapRouter>()

    div(
        "w-full md:w-max relative bottom-0 left-0 right-0 md:fixed md:right-auto z-[1035] ",
        id = "bottomBarMapContainer-${Id.next()}",
    ) {
        className(
            router.data.map { route ->
                if (route["page"] == Pages.Map.name
                    && route["card"].isNullOrBlank()
                    && route["add"].isNullOrBlank()
                    && route["show"].isNullOrBlank()
                    && route["edit"].isNullOrBlank()
                    && route["id"].isNullOrBlank()
                    && route["userId"].isNullOrBlank()
                    && route["change"].isNullOrBlank()
                    && route["mapLayer"].isNullOrBlank()
                ) "block" else "hidden"
            },
        )
        div(
            "w-full h-14.5 md:w-100 md:min-w-100 bg-formationWhite md:rounded-tr-2xl shadow-tlHalfCard md:shadow-tlFullCard",
            id = "new-bottom-bar-${Id.next()}",
        ) {
            bottomBarCore()
        }
    }
}

fun RenderContext.inlinedBottomBar() {
    div(
        "w-full h-14.5 md:min-w-100 bg-formationWhite shadow-tlHalfCard md:shadow-tlFullCard",
        id = "inlined-bottom-bar-${Id.next()}",
    ) {
        bottomBarCore()
    }
}

val addCircleButtonStyle: Style<BasicParams> = {
    width { "46px" }
    height { "46px" }
    background { color { primary.main } }
    color { secondary.main }
    border {
        width(formationStyles.borderWidth)
        color { primary.main }
    }
    boxShadow {
        shadow("2px", "4px", "10px", color = rgba(0, 0, 0, 0.35))
    }
}

fun RenderContext.bottomBarButton(
    bottomBarPage: Pages,
    icon: (Icons.() -> IconDefinition),
    iconSize: SizesProperty,
    title: Flow<String>,
    routingMap: Map<String, String>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    disabled: Boolean = false,
    prefix: String = "bottomButton",
    analyticsEventProvider: () -> MessageToServer.AnalyticsEvent
) {
    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()
    val activeBottomBarPageStore by koinCtx.inject<ActiveBottomBarPageStore>()

    button(
        prefix = prefix,
        style = {
            height { full }
            if (disabled) {
                color { FormationColors.GrayDisabled.color }
            } else {
                color { primary.main }
            }
            paddings {
                horizontal { small }
                vertical { tiny }
            }
        },
    ) {
        activeBottomBarPageStore.data.render { activeButton ->
            flexBox(
                {
                    height { full }
                    direction { column }
                    justifyContent { center }
                    alignItems { center }
                    wrap { nowrap }
                    if (disabled) {
                        color { FormationColors.GrayDisabled.color }
                    } else {
                        color {
                            if (activeButton == bottomBarPage) {
                                FormationColors.MarkerYou.color
                            } else primary.main
                        }
                    }

                },
            ) {
                attr("onClick", "blur();")
                title(
                    title.map { title ->
                        title.lowercase()
                            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                    },
                )
                icon(
                    {
                        size(iconSize)
                    },
                ) {
                    fromTheme(icon)
                }
                span(
                    {
                        fontSize { smaller }
                        fontWeight { semiBold }
                    },
                ) {
                    title.renderText(into = this)
                }
            }
        }
        if (!disabled) {
            with(clicks) {
                this.map { analyticsEventProvider.invoke() } handledBy analyticsService.analyticsEvent
                this.map { bottomBarPage } handledBy activeBottomBarPageStore.changePage
                clickHandlers?.forEach { handler ->
                    this handledBy handler
                }
            }
            if (routingMap != null) {
                clicks.map {
                    if (router.current["page"] != routingMap["page"]) {
                        routingMap
                    } else Pages.Map.route
                } handledBy routerStore.validateInternalRoute
            }
        }
    }
}
