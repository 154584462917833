package camera.cameraWrapper

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.muted
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.modal
import kotlinx.browser.document
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLVideoElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twLargeIconButton

fun cameraModal(
    toggleStore: Store<Boolean>,
    closeHandlers: List<SimpleHandler<Unit>>? = null,
    content: (HtmlTag<HTMLDivElement>.(
        closeModal: SimpleHandler<Unit>,
        modalOpened: Flow<Boolean>,
        videoElement: HTMLVideoElement?,
        canvasElement: HTMLCanvasElement?
    ) -> Unit)? = null,
) = modal {

    val cameraReady = storeOf(false)
    val canvasReady = storeOf(false)

    openState(toggleStore)

    modalPanel("fixed z-[2000] inset-0") {

        div("flex w-full h-full max-w-full max-h-full bg-formationBlack/75") {

            var videoElement: HTMLVideoElement? = null
            var canvasElement: HTMLCanvasElement? = null

            cameraReady.update(false)
            canvasReady.update(false)

            div("relative flex w-full h-full") {
                video(
                    baseClass = "w-full min-h-[200px] object-contain",
                ) {
                    muted(true)
                    val observer = MutationObserver { _, observer ->
                        if (document.contains(domNode)) {
                            videoElement = domNode
                            cameraReady.update(true)
                            observer.disconnect()
                        }
                    }
                    observer.observe(
                        document,
                        MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
                    )
                }

                canvas("absolute w-0 h-0") {
                    val canvasObserver = MutationObserver { _, observer ->
                        if (document.contains(domNode)) {
                            canvasElement = domNode
                            canvasReady.update(true)
                            observer.disconnect()
                        }
                    }
                    canvasObserver.observe(
                        document,
                        MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
                    )
                }

                div("absolute flex flex-col w-full h-full items-center justify-between") {
                    // top overlay
                    div("bg-formationWhite rounded-bl-xl self-end p-2") {

                        twLargeIconButton(FormationUIIcons.Close.icon) {
                            clicks handledBy {
                                closeHandlers?.let { handlers ->
                                    handlers.forEach {
                                        console.log("Invoke handler: $it")
                                        it.invoke(Unit)
                                    }
                                }
                            }
                            clicks handledBy {
                                close(Unit)
                            }
                        }
                    }
                    // bottom overlay
                    div("bg-formationWhite rounded-tr-xl self-start p-2") {
                        twColOf content@{
                            // Content that receives modal close handler and video and canvas elements
                            combine(cameraReady.data, canvasReady.data) { cam, can -> cam && can }.renderIf({ it }) {
                                content?.invoke(this@content, close, opened, videoElement, canvasElement)
                            }
                        }
                    }
                }
            }
        }
    }
}
