package twcomponents

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import kotlinx.coroutines.flow.flowOf
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement

fun RenderContext.twRowOf(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-row gap-2 sm:gap-5 items-center justify-start", id, scope) {
        content(this)
    }
}

fun RenderContext.twRowOfWrap(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-row flex-wrap gap-2 sm:gap-3 items-center justify-start", id, scope) {
        content(this)
    }
}

fun RenderContext.twRowOfJustifyBetween(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<Element>.() -> Unit
) {
    custom("row-with-justify-between", "flex flex-row gap-2 sm:gap-5 items-center justify-between", id, scope) {
        content(this)
    }
}

fun RenderContext.twRowOfJustifyEnd(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("w-full flex flex-row gap-2 sm:gap-5 items-center justify-end", id, scope) {
        content(this)
    }
}

fun RenderContext.twRowOfJustifyStart(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("w-full flex flex-row gap-2 sm:gap-5 items-center justify-start", id, scope) {
        content(this)
    }
}

fun RenderContext.twColOf(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-col gap-2", id, scope) {
        content(this)
    }
}

fun RenderContext.twColOfNoGap(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-col", id, scope) {
        additionalClasses?.let { className(flowOf(it)) }
        content(this)
    }
}

fun RenderContext.twRowOfNoGap(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-row w-full items-stretch justify-between", id, scope) {
        additionalClasses?.let { className(flowOf(it)) }
        content(this)
    }
}

fun RenderContext.twButtonRow(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-row gap-2 items-center justify-center", id, scope) {
        content(this)
    }
}

fun RenderContext.twContentBoxOf(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div(
        "flex flex-col gap-2 items-stretch justify-center border-b border-gray-200 hover:bg-gray-100 focus:bg-gray-100",
        id,
        scope,
    ) {
        additionalClasses?.let { className(flowOf(it)) }
        content(this)
    }
}
