package data.users.settings

import apiclient.users.NotificationSetting
import apiclient.users.NotificationSettingValue
import apiclient.users.UserPreferences
import apiclient.users.notificationPreferencesMap
import auth.ApiUserStore
import dev.fritz2.core.Id
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import localization.Locales
import services.UserService

class SyncedUserPreferencesStore : RootStore<UserPreferences>(
    initialData = UserPreferences(),
    job = Job(),
) {

    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val userService: UserService by koinCtx.inject()

    val reset = handle {
        console.log("Reset SyncedUserPreferencesStore")
        val browserLanguage = window.navigator.language.let { langId -> Locales.findByIdOrNull(langId) }
            ?: window.navigator.languages.mapNotNull { langId -> Locales.findByIdOrNull(langId) }.takeIf { it.isNotEmpty() }?.first()
            ?: Locales.EN_GB
        UserPreferences(languageCode = browserLanguage.id)
    }

    suspend fun fetchAndInitialize(): UserPreferences {
        val apiUser = apiUserStore.current.apiUser
        return if (apiUser != null && !apiUser.isAnonymous) {
            val preferencesResult = userService.getUserPreferencesById(apiUserStore.current.userId)
            return preferencesResult.fold(
                { userPreferences ->
                    console.log("⇊ Fetched user preferences:", userPreferences.toString())
                    update(userPreferences)
                    userPreferences
                },
                {
                    console.log("Fetching user preferences failed.", it)
                    current
                },
            )
        } else current
    }

    val fetchAndUpdate = handle { current ->
        coroutineScope {
            CoroutineName("get-user-preferences-${Id.next()}")
            launch {
                val apiUser = apiUserStore.current.apiUser
                if (apiUser != null && !apiUser.isAnonymous) {
                    val preferencesResult = userService.getUserPreferencesById(apiUserStore.current.userId)
                    preferencesResult.fold(
                        { userPreferences ->
                            update(userPreferences)
                            console.log("⇊ Fetched user preferences:", userPreferences.toString())
                        },
                        {
                            console.log("Fetching user preferences failed.", it)
                            update(current)
                        },
                    )
                }
            }
        }
        current
    }

    private val uploadPreferences = handle<UserPreferences?> { current, new ->
        val user = apiUserStore.current
        if (!user.isAnonymous && user.apiUser != null) {
            console.log("⇈ Upload user preferences...")
            coroutineScope {
                CoroutineName("update-user-preferences-${Id.next()}")
                launch {
                    (new ?: current).let {
                        userService.updateUserPreferences(
                            userId = user.userId,
                            preferences = it,
                        )
                    }
                }
            }
        }
        new ?: current
    }

    val savePreferences = handle { current ->
        uploadPreferences(null)
        current
    }

    val updateLanguage = handle<String> { current, languageCode ->
        if (current.languageCode != languageCode) {
            uploadPreferences(current.copy(languageCode = languageCode))
            console.log("Changed language preference to ->", languageCode)
            current.copy(languageCode = languageCode)
        } else current
    }

    val updateTimeZone = handle<String> { current, timeZone ->
        if (current.timeZone != timeZone) {
            uploadPreferences(current.copy(timeZone = timeZone))
            console.log("Changed timeZone preference to ->", timeZone)
            current.copy(timeZone = timeZone)
        } else current
    }

    val updateSharingState = handle<Boolean> { current, sharingState ->
//        val newSharingState = when (sharingState) {
//            true -> current.rememberLocationSharing.takeIf { true }
//            false -> if (current.rememberLocationSharing == true) false else null
//        }
//        val newSharingState = current.rememberLocationSharing.takeIf { sharingState }
        if (current.locationSharingState != sharingState) {
            uploadPreferences(current.copy(locationSharingState = sharingState))
            console.log("Changed sharingState to ->", sharingState)
            current.copy(locationSharingState = sharingState)
        } else current
    }

    val updateNotificationSettings = handle<Map<NotificationSetting, NotificationSettingValue>> { current, newSettings ->
        if (current.notificationPreferencesMap != newSettings) {
            val updatedPreferences = newSettings.entries.map { setting ->
                "${setting.key.name}:${setting.value.name}"
            }
            uploadPreferences(current.copy(notificationPreferences = updatedPreferences))
            console.log("Changed notification preferences to ->", updatedPreferences.toString())
            current.copy(notificationPreferences = updatedPreferences)
        } else current
    }

}
