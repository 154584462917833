package data.objects.views.objectrouting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.tags.getUniqueTag
import auth.Features
import data.objects.ActiveObjectStore
import data.objects.building.ActiveBuildingStore
import data.objects.building.ActiveFloorLevelStore
import data.objects.building.CurrentBuildingsStore
import data.objects.building.getBuilding
import data.objects.building.getFloorLevel
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import dev.fritz2.styling.theme.IconDefinition
import koin.koinCtx
import koin.withKoin
import kotlin.math.max
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import location.GeoPositionStore
import location.geolocation.latLon
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import point2pointnavigation.RoutingService
import services.GeoPositionService
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twColOfNoGap
import twcomponents.twFeatureFlagDiv
import twcomponents.twFlatBoxColStart
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatIconBox
import twcomponents.twFlatIconButton
import twcomponents.twIconMedium
import twcomponents.twObjectSelector
import twcomponents.twRowOfNoGap
import twcomponents.twRowOfWrap
import utils.ToggleStore
import utils.getIcon
import utils.getName
import webcomponents.Position

enum class NavigationOrigin(val icon: IconDefinition) {
    LastKnownPosition(FormationIcons.LocationAlt.icon),
    GPSPosition(FormationIcons.Position.icon),
    MapCenter(FormationIcons.MapCenter.icon),
    OtherObject(FormationIcons.TrackedObject.icon)
}

data class RoutePoint(
    val lat: Double,
    val lon: Double,
    val floorId: String? = null,
    val floorLevel: Double? = null,
    val isFloorChange: Boolean = false,
    val floorChange: FloorChange? = null
)

data class FloorChange(
    val previousLevel: Double,
    val newLevel: Double
) {
    val isLevelUp get() = previousLevel < newLevel
}

fun RenderContext.objectRouting() {
    twFeatureFlagDiv(flag = Features.EnableRouteToObject) {
        twColOfNoGap {
            className("w-full")
            withKoin {
                val activeObjectStore: ActiveObjectStore = koinCtx.get()
                val destinationObject = activeObjectStore.current
                val maplibreMap: MaplibreMap = koinCtx.get()
                val routingMenuToggle = ToggleStore()
                val objectSelectorToggle = ToggleStore()
                val selectedObjectStore = storeOf<GeoObjectDetails?>(null)
                val selectedNavigationOriginStore = storeOf<NavigationOrigin?>(null)
                val selectedNavigationOriginCoordinatesStore = storeOf<LatLon?>(null)
                val geoPositionService: GeoPositionService = koinCtx.get()
                val lastKnownPositionStore: LastKnownPositionStore = koinCtx.get()
                val currentBuildingsStore: CurrentBuildingsStore = koinCtx.get()
                val activeFloorLevelStore: ActiveFloorLevelStore = koinCtx.get()
                val activeBuildingsStore: ActiveBuildingStore = koinCtx.get()

                twRowOfNoGap {
                    twFlatIconBox {
                        twIconMedium(FormationUIIcons.Routing.icon)
                    }
                    twFlatBoxRowCenter {
                        p("text-sm truncate") {
                            +"Navigation to this ${destinationObject.objectType.getName()}"
                        }
                    }
                    routingMenuToggle.data.render { navEnabled ->
                        twFlatIconButton(
                            icon = if (navEnabled) {
                                FormationUIIcons.Close.icon
                            } else FormationUIIcons.ArrowDown.icon,
                        ) {
                            p("text-xs truncate") {
                                routingMenuToggle.data.render { navEnabled ->
                                    if (navEnabled) {
                                        +"Close"
                                    } else {
                                        +"Open"
                                    }
                                }
                            }
                            clicks handledBy {
                                selectedNavigationOriginCoordinatesStore.update(null)
                                selectedNavigationOriginStore.update(null)
                                maplibreMap.showRoutingPath(listOf())
                                routingMenuToggle.toggle()
                            }
                        }
                    }
                }

                routingMenuToggle.data.render { showNavSettings ->
                    if (showNavSettings) {
                        // check last known position store here and update selectedNavigationOriginStore with it
                        lastKnownPositionStore.current?.latLon?.let { lastPosition ->
                            selectedNavigationOriginStore.update(NavigationOrigin.LastKnownPosition)
                            selectedNavigationOriginCoordinatesStore.update(lastPosition)
                        }

                        twFlatBoxColStart {
                            className("px-2")
                            span("text-sm py-2") {
                                +"From: "
                            }
                            selectedNavigationOriginStore.data.render { navOrigin ->
                                navOrigin?.let {
                                    twFlatIconButton(icon = FormationUIIcons.Select.icon) {
                                        className("mb-2")
                                        twIconMedium(
                                            icon = when (navOrigin) {
                                                NavigationOrigin.LastKnownPosition -> {
                                                    when (lastKnownPositionStore.current?.type) {
                                                        PositionType.GPS -> FormationIcons.Position.icon
                                                        PositionType.ScannedObject -> FormationIcons.QRCode.icon
                                                        null -> navOrigin.icon
                                                    }
                                                }

                                                NavigationOrigin.OtherObject -> {
                                                    selectedObjectStore.current?.iconCategory?.getIcon()?.icon
                                                        ?: navOrigin.icon
                                                }

                                                else -> navOrigin.icon
                                            },
                                        )
                                        p("text-xs truncate") {
                                            +when (navOrigin) {
                                                NavigationOrigin.OtherObject -> selectedObjectStore.current?.title
                                                    ?: ""

                                                else -> navOrigin.name
                                            }
                                        }
                                        clicks handledBy {
                                            selectedNavigationOriginStore.update(null)
                                        }
                                    }
                                }
                            }

                            selectedNavigationOriginStore.data.render { navOrigin ->
                                if (navOrigin == null) {
                                    twColOf {
                                        className("w-full")
                                        // select origin: last known location, GPS, Map center or other object
                                        twRowOfWrap {
                                            className("mb-2")
                                            NavigationOrigin.entries.forEach { entry ->
                                                twFlatIconButton(
                                                    icon = when (entry) {
                                                        NavigationOrigin.LastKnownPosition -> {
                                                            when (lastKnownPositionStore.current?.type) {
                                                                PositionType.GPS -> FormationIcons.Position.icon
                                                                PositionType.ScannedObject -> FormationIcons.QRCode.icon
                                                                null -> entry.icon
                                                            }
                                                        }

                                                        else -> entry.icon
                                                    },
                                                    iconPosition = Position.Left,
                                                    disabled = when (entry) {
                                                        NavigationOrigin.LastKnownPosition -> {
                                                            lastKnownPositionStore.data.map { it?.latLon == null }
                                                        }

                                                        else -> flowOf(false)
                                                    },
                                                ) {
                                                    p("text-xs truncate") {
                                                        +entry.name
                                                    }
                                                    clicks handledBy {
                                                        when (entry) {
                                                            NavigationOrigin.LastKnownPosition -> {
                                                                if (lastKnownPositionStore.current != null) {
                                                                    selectedNavigationOriginStore.update(entry)
                                                                }
                                                            }

                                                            NavigationOrigin.GPSPosition -> {
                                                                geoPositionService.getActiveWatchIdOrNew()
                                                                selectedNavigationOriginStore.update(entry)
                                                            }

                                                            NavigationOrigin.OtherObject -> {
                                                                selectedObjectStore.update(null)
                                                                objectSelectorToggle.toggle()
                                                                coroutineScope {
                                                                    launch {
                                                                        selectedObjectStore.data.handledBy { obj ->
                                                                            obj?.let {
                                                                                selectedNavigationOriginStore.update(
                                                                                    entry,
                                                                                )
                                                                                objectSelectorToggle.toggle()
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            else -> {
                                                                selectedNavigationOriginStore.update(entry)
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        objectSelectorToggle.data.render { open ->
                                            if (open) {
                                                twColOf {
                                                    className("w-full mb-2")
                                                    twObjectSelector(selectedObjectStore)
                                                }
                                            } else {
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        twRowOfNoGap {

                            twFlatIconButton(icon = FormationIcons.Position.icon) {
                                className("grow")
                                p("text-sm truncate") {
                                    +"Go to Start"
                                }
                                clicks handledBy {
                                    val latLon = getLatestLatLon(
                                        origin = selectedNavigationOriginStore.current,
                                        selectedObjectStore = selectedObjectStore,
                                    )
                                    latLon?.let {
                                        maplibreMap.easeTo(
                                            center = it,
                                            zoom = max(maplibreMap.getZoom(), 19.0),
                                            duration = 1000.0,
                                        )
                                    }
                                    val floorId = getStartFloorId(
                                        origin = selectedNavigationOriginStore.current,
                                        latLon = latLon,
                                        selectedObjectStore = selectedObjectStore,
                                    )
                                    val building = (floorId?.let { currentBuildingsStore.current.getBuilding(floorId) }
                                        ?: currentBuildingsStore.current[activeBuildingsStore.current])
                                    (building?.getFloorLevel(floorId)
                                        ?: building?.floorData?.keys?.filter { it >= 0 }?.min()
                                        ?: building?.defaultFloorLevel)?.let { floorLevel ->
                                        activeFloorLevelStore.update(floorLevel)
                                    }
                                }
                            }

                            twFlatIconButton(icon = FormationUIIcons.NavigationArrow.icon) {
                                className("grow")
                                p("text-sm truncate") {
                                    +"Show the Route"
                                }

                                clicks handledBy {

                                    val latLon: LatLon? = getLatestLatLon(
                                        origin = selectedNavigationOriginStore.current,
                                        selectedObjectStore = selectedObjectStore,
                                    )

                                    val startFloorId: String? = getStartFloorId(
                                        origin = selectedNavigationOriginStore.current,
                                        latLon = latLon,
                                        selectedObjectStore = selectedObjectStore,
                                    )

                                    val targetFloorId = destinationObject.tags.getUniqueTag(ObjectTags.ConnectedTo)

                                    // change floor to known level when possible
                                    when (selectedNavigationOriginStore.current) {
                                        NavigationOrigin.LastKnownPosition -> {
                                            if (lastKnownPositionStore.current?.type == PositionType.ScannedObject) {
                                                startFloorId?.let { floorId ->
                                                    currentBuildingsStore.current.getBuilding(floorId)?.getFloorLevel(floorId)?.let { floorLevel ->
                                                        activeFloorLevelStore.update(floorLevel)
                                                    }
                                                }
                                            }
                                        }

                                        NavigationOrigin.OtherObject -> {
                                            startFloorId?.let { floorId ->
                                                currentBuildingsStore.current.getBuilding(floorId)?.getFloorLevel(floorId)?.let { floorLevel ->
                                                    activeFloorLevelStore.update(floorLevel)
                                                }
                                            }
                                        }

                                        else -> {}
                                    }

                                    // update coordinates store
                                    selectedNavigationOriginCoordinatesStore.update(latLon)
                                    // calculate new route points and display navigation path on the map
                                    latLon?.let { from ->
                                        RoutingService.navigate(
                                            from = from,
                                            to = destinationObject.latLon,
                                            startFloorId = startFloorId,
                                            targetFloorId = targetFloorId,
                                        )
                                    }?.let { routingPoints ->
                                        val listOfRoutePoints = routingPoints.mapIndexed { index, routePoint ->
                                            val floorId = routePoint.tags.getUniqueTag(ObjectTags.ConnectedTo)
                                            val floorLevel = floorId?.let {
                                                currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                                            }
                                            val previousFloorId = if (index > 0) {
                                                routingPoints[index - 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                                            } else null
                                            val previousLevel = previousFloorId?.let {
                                                currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                                            }
                                            val nextFloorId = if (index < routingPoints.size - 1) {
                                                routingPoints[index + 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                                            } else null
                                            val nextLevel = nextFloorId?.let {
                                                currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                                            }
                                            val isLevelDown = previousFloorId?.let { previous -> floorId?.let { pointFloorId -> previous != pointFloorId } }
                                                ?: false
                                            val isLevelUp = nextFloorId?.let { next -> floorId?.let { pointFloorId -> next != pointFloorId } }
                                                ?: false

                                            RoutePoint(
                                                lat = routePoint.latLon.lat,
                                                lon = routePoint.latLon.lon,
                                                floorId = floorId,
                                                floorLevel = floorLevel,
                                                isFloorChange = isLevelUp || isLevelDown,
                                                floorChange = floorLevel?.let {
                                                    if (isLevelUp) {
                                                        nextLevel?.let {
                                                            FloorChange(
                                                                previousLevel = floorLevel,
                                                                newLevel = nextLevel,
                                                            )
                                                        }
                                                    } else if (isLevelDown) {
                                                        previousLevel?.let {
                                                            FloorChange(
                                                                previousLevel = previousLevel,
                                                                newLevel = floorLevel,
                                                            )
                                                        }
                                                    } else null
                                                },
                                            )
                                        }

                                        maplibreMap.showRoutingPath(
                                            routePoints =
                                            listOfNotNull(
                                                RoutePoint(
                                                    lat = latLon.lat,
                                                    lon = latLon.lon,
                                                    floorId = startFloorId,
                                                ),
                                            ) + listOfRoutePoints + listOfNotNull(
                                                RoutePoint(
                                                    lat = destinationObject.latLon.lat,
                                                    lon = destinationObject.latLon.lon,
                                                    floorId = targetFloorId,
                                                ),
                                            ),
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun getLatestLatLon(origin: NavigationOrigin?, selectedObjectStore: Store<GeoObjectDetails?>): LatLon? {
    val maplibreMap: MaplibreMap = koinCtx.get()
    val geoPositionStore: GeoPositionStore = koinCtx.get()
    val lastKnownPositionStore: LastKnownPositionStore = koinCtx.get()

    // gather latest coordinates for new route
    return when (origin) {
        NavigationOrigin.LastKnownPosition -> lastKnownPositionStore.current?.latLon
        NavigationOrigin.GPSPosition -> geoPositionStore.current?.latLon()
        NavigationOrigin.MapCenter -> maplibreMap.getCenter().toLatLon()
        NavigationOrigin.OtherObject -> selectedObjectStore.current?.latLon
        null -> null
    }
}

fun getStartFloorId(origin: NavigationOrigin?, latLon: LatLon?, selectedObjectStore: Store<GeoObjectDetails?>): String? {
    val lastKnownPositionStore: LastKnownPositionStore = koinCtx.get()
    val currentBuildingsStore: CurrentBuildingsStore = koinCtx.get()

    return when (origin) {
        NavigationOrigin.LastKnownPosition -> {
            when (lastKnownPositionStore.current?.type) {
                PositionType.ScannedObject -> {
                    lastKnownPositionStore.current?.geoObjectDetails?.tags
                        ?.getUniqueTag(ObjectTags.ConnectedTo)
                }

                else -> {
                    currentBuildingsStore.pointWithinFloor(latLon)
                }
            }
        }

        NavigationOrigin.GPSPosition -> currentBuildingsStore.pointWithinFloor(latLon)
        NavigationOrigin.MapCenter -> currentBuildingsStore.pointWithinFloor(latLon)
        NavigationOrigin.OtherObject -> selectedObjectStore.current?.tags?.getUniqueTag(ObjectTags.ConnectedTo)
        null -> null
    }
}
