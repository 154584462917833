package data.objects.views.directediting

import apiclient.FormationClient
import apiclient.customfields.parseFieldValues
import apiclient.geoobjects.AddKeywords
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.RemoveKeywords
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.applyObjectChanges
import apiclient.geoobjects.keywordsForQueryContext
import apiclient.geoobjects.newContext
import auth.CurrentWorkspaceStore
import data.objects.ActiveObjectStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import localization.translate
import model.L
import overlays.BusyStore
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.fadeInFadeoutTransition
import twcomponents.toggleClassOnElement
import twcomponents.twColOf
import twcomponents.twIconSmall
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMediumIconButtonHighlight
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfWrap
import twcomponents.twTagButton
import utils.extractReadOnlyTags
import utils.insertObjectInCachesAndMap
import utils.mergeTagLists
import utils.respectFeatureFlags
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.inputLabelWrapper
import webcomponents.keywordTagList

fun RenderContext.tagsSection() {
    withKoin {
        val activeObjectStore: ActiveObjectStore = get()
        val currentWorkspaceStore: CurrentWorkspaceStore = get()
        val client: FormationClient = get()
        val busyStore: BusyStore = get()
        val translation: Translation = get()

        val editingStore = storeOf(false)
        val isEditable = activeObjectStore.current.editable
        val objectId = activeObjectStore.current.id
        val tagsSub = activeObjectStore.map(GeoObjectDetails.L.tags)
        val keywordsSub = activeObjectStore.map(GeoObjectDetails.L.keywords)
        val readOnlyKeywordTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.extractReadOnlyTags()
        val fieldValueTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.respectFeatureFlags().map { tags ->
            currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
                tags.parseFieldValues(definitions)
            }
        }.map { it?.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) ?: emptyList() }

        editingStore.data.render { isEditing ->
            val newKeywordStore = storeOf("")
            val suggestedTagsStore = storeOf<List<String>>(emptyList())

            div("-m-2 p-2") {
                if (isEditing) className("bg-gray-200")
                div("mt-3 mb-1") {
                    twRowOfJustifyBetween {
                        div(
                            baseClass = "text-lg py-2 flex flex-row gap-2 place-items-center font-bold tracking-widest underline decoration-highlight",
                        ) {
                            twIconSmall(FormationIcons.Tag.icon)
                            translate(TL.KeywordTags.CARD_TITLE)
                        }
                        if (isEditable) {
                            // show edit/close buttons
                            val id = Random.nextULong().toString()
                            div("static flex flex-row h-0 w-full pr-2 items-center justify-end") {
                                div("relative flex flex-row w-max gap-5 py-2 px-4 rounded-xl shadow-xl", id = id) {
                                    if (isEditing) {
                                        if (domNode.classList.contains("hidden")) {
                                            domNode.classList.remove("hidden")
                                        }
                                    } else domNode.classList.add("hidden")
                                    fadeInFadeoutTransition()
                                    if (editingStore.current) {
                                        className("text-formationWhite bg-formationBlack")
                                        twMediumIconButtonHighlight(icon = FormationUIIcons.Close.icon) {
                                            clicks.map { !editingStore.current } handledBy editingStore.update
                                        }
                                    } else {
                                        className("bg-gray-300")
                                        twMediumIconButtonNeutral(FormationIcons.Edit.icon) {
                                            clicks.map { !editingStore.current } handledBy editingStore.update
                                        }
                                    }
                                }
                            }
                            if (!isEditing) {

                                this@div.toggleClassOnElement(
                                    className = "hidden",
                                    elementId = id,
                                )
                            }
                        }
                    }
                }
                if (isEditing) {
                    combine(tagsSub.data, keywordsSub.data) { t, k -> Pair(t, k) }.render { (tags, keywords) ->
                        twColOf {
                            val roTags = tags.extractReadOnlyTags().mapNotNull { it.readOnlyStringValue }

                            val currentKeywords = keywords.orEmpty().toSet() - roTags.toSet()
                            twRowOfWrap {
                                currentKeywords.forEach { keyword ->
                                    twTagButton(text = keyword, icon = FormationUIIcons.Close.icon) {
                                        clicks handledBy {
                                            busyStore.handleApiCall(
                                                supplier = {
                                                    client.applyObjectChanges(ObjectChanges(objectId, RemoveKeywords(listOf(keyword))))
                                                },
                                                processResult = { geoObjects ->
                                                    val updatedActiveObj = geoObjects.firstOrNull { it.id == objectId }
                                                    updatedActiveObj?.let { updated ->
                                                        keywordsSub.update(updated.keywords)
                                                    }
                                                },
                                            )
                                        }
                                    }
                                }
                            }

                            div("p-2") {
                                inputLabelWrapper(
                                    title = translation[TL.KeywordTags.CREATE_TAG],
                                    visibilityFlow = newKeywordStore.data.map { it.isNotBlank() },
                                ) {
                                    twInputField(newKeywordStore) {
                                        twInputTextField {
                                            id("newKeywordInput")
                                            placeholder(translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER])
                                        }
                                        twRightAlignedButtonRow {
                                            newKeywordStore.data.render { newKw ->
                                                if (newKw.isNotBlank()) {
                                                    twRevertButton(newKeywordStore, "")
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            newKeywordStore.data.debounce(150.milliseconds).render { enteredKeyword ->
                                if (enteredKeyword.isNotBlank()) {
                                    twRowOfWrap {
                                        if (!currentKeywords.contains(enteredKeyword)) {
                                            twTagButton(enteredKeyword, icon = FormationIcons.Add.icon) {
                                                clicks handledBy {
                                                    busyStore.handleApiCall(
                                                        supplier = {
                                                            client.applyObjectChanges(ObjectChanges(objectId, AddKeywords(listOf(enteredKeyword))))
                                                        },
                                                        processResult = { geoObjects ->
                                                            val updatedActiveObj = geoObjects.firstOrNull { it.id == objectId }
                                                            updatedActiveObj?.let { updated ->
                                                                keywordsSub.update(updated.keywords)
                                                                insertObjectInCachesAndMap(updated)
                                                            }
                                                        },
                                                    )
                                                }
                                            }
                                        }

                                        CoroutineScope(job).launch {
                                            val suggested = client.keywordsForQueryContext(
                                                SearchQueryContext.newContext(listOf(currentWorkspaceStore.current?.groupId!!)),
                                                enteredKeyword,
                                                20,
                                            ).getOrNull().orEmpty().filter { keyword ->
                                                keyword != enteredKeyword && !currentKeywords.contains(keyword)
                                            }
                                            suggestedTagsStore.update(suggested)
                                        }

                                        suggestedTagsStore.data.render { suggestedKeywords ->
                                            suggestedKeywords.forEach { suggestedKeyword ->
                                                twTagButton(suggestedKeyword, icon = FormationIcons.Add.icon) {
                                                    clicks handledBy {
                                                        busyStore.handleApiCall(
                                                            supplier = {
                                                                client.applyObjectChanges(
                                                                    ObjectChanges(
                                                                        objectId,
                                                                        AddKeywords(listOf(suggestedKeyword)),
                                                                    ),
                                                                )
                                                            },
                                                            processResult = { geoObjects ->
                                                                val updatedActiveObj = geoObjects.firstOrNull { it.id == objectId }
                                                                updatedActiveObj?.let { updated ->
                                                                    keywordsSub.update(updated.keywords)
                                                                    insertObjectInCachesAndMap(updated)
                                                                }
                                                            },
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    div("max-h-full min-h-max mt-1 px-2") {
                        keywordTagList(
                            keywords = mergeTagLists(
                                readOnlyTags = readOnlyKeywordTags,
                                keywords = keywordsSub.data,
                                fieldValueTags = fieldValueTags,
                            ),
                            searchable = true,
                            keywordTagType = KeywordTagType.ObjectTag,
                        )
                    }
                }
            }
        }
    }
}

