package search.searchlayer

import auth.ApiUserStore
import auth.WorkspacesStore
import apiclient.groups.Group
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerType
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job

class MapLayerMetadataListStore : RootStore<List<LayerMetaData>>(
    initialData = emptyList(),
    job = Job(),
) {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    private val workspacesStore: WorkspacesStore by koinCtx.inject()

    val updateLayerMetadataList = handle<List<Group>> { _, groups ->
        // get layerConfigs for all layers in all groups
        val layerMetadataList = groups.flatMap { group ->
            val layers = group.layerDefaultSettings?.sortedBy { it.layerType.name } ?: listOf()
            layers.filter {
                it.layerType != LayerType.Standard
            }
        }
        update(layerMetadataList)
        layerMetadataList
    }

    fun getDefaultState(layerId: String): Boolean {
        return current.firstOrNull { it.id == layerId }?.defaultOn ?: false
    }

    init {
        workspacesStore.data handledBy updateLayerMetadataList
    }
}
