package layercache

import apiclient.FormationClient
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.restGetObjectById
import apiclient.geoobjects.restMultiGet
import apiclient.util.Cache
import apiclient.util.simpleCache
import indexeddb.dbScope
import koin.withKoin
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.koin.core.time.measureDuration

class GeoObjectDetailsCache(
    private val simpleCache: Cache<String, GeoObjectDetails> = simpleCache(
        capacity = 10_000,
        maxAgeInMillis = 4.hours.inWholeMilliseconds,
    ),
    private val database: GeoObjectDetailsDatabase,
) : Cache<String, GeoObjectDetails> by simpleCache {
    suspend fun fetch(
        id: String,
        formationClient: FormationClient,
        skipCache: Boolean
    ): Pair<GeoObjectDetails, Boolean>? {
        suspend fun fetchInternal(
            id: String,
            formationClient: FormationClient,
            skipCache: Boolean
        ): Pair<GeoObjectDetails, Boolean>? {
            val r = database.getOrFetchById(id, skipCache) { id ->
                console.warn("should not have needed to fetch id", id)
                formationClient.restGetObjectById(id).getOrNull()
            }
            return r
        }

        return fetchInternal(id, formationClient, skipCache)?.also { (it, cachedResult) ->
            simpleCache.put(it.id, it)
        }
    }

    /***
     * boolean indicates if the result was pulled from cache
     */
    suspend fun fetchResult(
        id: String,
        formationClient: FormationClient,
        skipCache: Boolean
    ): Result<Pair<GeoObjectDetails, Boolean>> {
        return fetch(id, formationClient, skipCache).let {
            if (it != null) {
                Result.success(it)
            } else {
                Result.failure(NullPointerException("Object id not resolved"))
            }
        }
    }

    fun getExisting(
        ids: List<String>,
    ): List<GeoObjectDetails> {
        return simpleCache.multiGet(ids)
    }

    private suspend fun refreshIds(
        ids: List<String>,
        formationClient: FormationClient,
    ): List<GeoObjectDetails> {

        if (ids.isEmpty()) {
            return emptyList()
        }

        val results = ids.takeUnless { it.isEmpty() }?.let {
            try {
                formationClient.restMultiGet(
                    ids = it,
                    chunkSize = 250,
                    includeDeleted = true,
                ).getOrThrow()
            } catch (e: Throwable) {
                console.error("Error restMultiGet", e.message)
                emptyList()
            }
        }.orEmpty()

        database.putAll(results)
        return results
    }

    suspend fun multiGet(
        ids: List<String>,
        formationClient: FormationClient,
        forceUpdate: Boolean = false,
    ): List<GeoObjectDetails> {
        val cachedResults = if (forceUpdate) {
            refreshIds(ids, formationClient)
        } else {
            simpleCache.multiGet(ids)
        }

        val missingIds = ids - cachedResults.map { it.id }.toSet()

        if (missingIds.isEmpty()) {
            return cachedResults
        }


        val results = refreshIds(missingIds,formationClient)

        results.forEach {
            simpleCache[it.id] = it
        }

        val mergedResults = (cachedResults + results)
        database.putAll(mergedResults)

        return mergedResults.toList()
    }


    suspend fun update(k: String, v: GeoObjectDetails): GeoObjectDetails {
        database.set(k, v)
        return super.set(k, v)
    }

    suspend fun updateMultiple(data: List<GeoObjectDetails>) {
        database.putAll(data)
        simpleCache.putAll(data.associateBy { it.id })
    }

    override fun set(k: String, v: GeoObjectDetails): GeoObjectDetails {
        dbScope.launch { database.set(k, v) }
        return super.set(k, v)
    }

    suspend fun delete(id: String) {
        database.delete(id)
        remove(id)
    }

    init {
        dbScope.launch {
            withKoin {
                while(true) {
                    try {
                        val outdated  = database.getOutdated(500)
                        if(outdated.isNotEmpty()) {
                            measureDuration {
                                refreshIds(outdated, get())
                            }.also {
                                console.log("refreshed ${outdated.size} outdated objects")
                            }
                        }
                    } catch (e: Exception) {
                        console.info("skipping outdated object refresh due to exception",e)
                    }
                    delay(5.minutes)
                }
            }
        }
    }
}
