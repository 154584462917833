package twcomponents

import apiclient.FormationClient
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.newContext
import apiclient.geoobjects.restSearch
import apiclient.tags.tag
import auth.CurrentWorkspaceStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import org.w3c.dom.HTMLInputElement
import search.generalListEntry
import web.dom.document
import webcomponents.KeywordTagType
import webcomponents.inputLabelWrapper

fun RenderContext.twObjectSelector(selectedObjectStore: Store<GeoObjectDetails?>) {
    withKoin {
        val formationClient = get<FormationClient>()
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()

        val searchBoxStore = storeOf("")
        val resultsStore = storeOf<List<GeoObjectDetails>>(emptyList())

        val resultsPopupId = "AddObjectQueryResultPopup"

        searchBoxStore.data.debounce(300.milliseconds) handledBy { q ->
            if (q.isNotBlank()) {
                val result = formationClient.restSearch(
                    SearchQueryContext.newContext(listOf(currentWorkspaceStore.current?.groupId ?: error("no group"))).copy(
                        from = 0,
                        size = 15,
                        text = q,
                        objectTypes = listOf(
                            ObjectType.ObjectMarker, ObjectType.Task, ObjectType.GeneralMarker, ObjectType.UserMarker, ObjectType.Zone,
                            ObjectType.POI, ObjectType.Event,
                        ),
                        excludeTags = listOf(
                            ObjectTags.Archived.tag("true"),
                            ObjectTags.Deleted.tag("true"),
                            ObjectTags.Flagged.tag("true"),
                        ),
                    ),
                )
                if (result.isFailure) {
                    console.error("Error searching for ${q}", result.exceptionOrNull())
                } else {
                    val results = result.getOrThrow().hits.map { it.hit }
                    document.getElementById(resultsPopupId)?.classList?.remove("hidden")
                    resultsStore.update(results)
                }
            } else {
                resultsStore.update(emptyList())
            }
        }

        inputLabelWrapper(
            title = flowOf("Find an object"),
            visibilityFlow = searchBoxStore.data.map { it.isNotBlank() },
        ) {
            twInputField(searchBoxStore) {
                twInputTextField {
                    placeholder("Find an object...")

                    inputs.debounce(500) handledBy {
                        val input = it.target as HTMLInputElement
                        document.getElementById(resultsPopupId)?.let { element ->
                            if (input.value.isNotBlank()) {
                                element.classList.remove("hidden")
                            } else {
                                element.classList.add("hidden")
                            }
                        }
                    }
                }
            }
        }

        resultsStore.data.render { results ->
            if (results.isNotEmpty()) {
                div(
                    "relative hidden top-0 max-h-96 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg overflow-y-auto",
                    resultsPopupId,
                ) {
                    results.forEach { obj ->
                        div("p-1 cursor-pointer hover:text-highlight") {
                            generalListEntry(
                                obj,
                                withBorder = false,
                                tagsSearchable = false,
                                keywordTagType = KeywordTagType.ObjectTag,
                                hoverEffect = false,
                            )
                            clicks handledBy {
                                searchBoxStore.update("")
                                selectedObjectStore.update(obj)
                                document.getElementById(resultsPopupId)?.classList?.add("hidden")
                            }
                        }
                    }
                }
            } else {
                console.log("hiding ${resultsStore.current.size}")
            }
        }
    }
}

