package maplibreGL

import auth.ApiUserStore
import apiclient.geoobjects.GeoObjectDetails
import data.connectableshapes.DeleteConnectionConnectorIdStore
import data.connectableshapes.NewConnectableShapeConnectionStore
import data.objects.ActiveObjectStore
import data.objects.objecthistory.ActiveHistoryEntryStore
import data.users.ActiveUserStore
import data.users.settings.LocalSettingsStore
import koin.koinCtx
import kotlin.collections.Map

object GeojsonConverter {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val activeUserStore: ActiveUserStore by koinCtx.inject()
    private val activeHistoryEntryStore: ActiveHistoryEntryStore by koinCtx.inject()
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()
//    val featureCache = SimpleCache<String, GeoJSONFeature>()
    private val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
    private val deleteConnectionConnectorIdStore: DeleteConnectionConnectorIdStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()

    fun fromGeoObject(
        geoObject: GeoObjectDetails,
        connectables: Map<String, MaplibreMap.ConnectableAndPosition>? = null,
        overlappingShapeIds: Set<String>? = null,
        activeOverride: Boolean? = null,
        desaturated: Boolean = false
    ): MapMarker? {
        val activeObjectId = when (activeOverride) {
            true -> geoObject.id
            false -> null
            else -> activeObjectStore.current.id
        }
        val activeHistoryEntryId = when (activeOverride) {
            true -> geoObject.id
            false -> null
            else -> activeHistoryEntryStore.current?.id
        }
        val activeUserId = activeUserStore.current.userId

        return generateMapMarker(
            geoObject = geoObject,
            connectables = connectables,
            overlappingShapeIds = overlappingShapeIds,
            activeObjectId = activeObjectId,
            activeUserId = activeUserId,
            activeHistoryEventId = activeHistoryEntryId,
            desaturated = desaturated,
            activeSourceConnectorId = newConnectableShapeConnectionStore.current.sourceConnectorId,
            activeTargetConnectorId = newConnectableShapeConnectionStore.current.targetConnectorId,
            activeTargetId = newConnectableShapeConnectionStore.current.targetMarkerId,
            activeConnectionDeleteConnectorId = deleteConnectionConnectorIdStore.current,
            highlightedConnector = maplibreMap.mouseOverConnector,
            highlightedConnection = maplibreMap.mouseOverConnection
        )
    }
}
