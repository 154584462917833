package map

import apiclient.geoobjects.ObjectType
import apiclient.util.isNotNullOrEmpty
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import data.keywordlayer.cardManageMapLayer
import data.objects.views.Delete
import data.objects.views.attachments.cardImagePreview
import data.objects.views.attachments.cardMarkdownPreview
import data.objects.views.cardAttribution
import data.objects.views.cardClusterPreview
import data.objects.views.cardCreate
import data.objects.views.cardCustomData
import data.objects.views.cardCustomizeBadge
import data.objects.views.cardDateTime
import data.objects.views.cardDeleteConfirmation
import data.objects.views.cardDescription
import data.objects.views.cardDetails
import data.objects.views.cardEdit
import data.objects.views.cardEditPosition
import data.objects.views.cardManageFieldValueTag
import data.objects.views.cardManageKeywordTags
import data.objects.views.cardObjectHistory
import data.objects.views.cardSecondaryMenu
import data.objects.views.cardShowKeywordTags
import data.objects.views.cardViewDescription
import data.objects.views.cardinfo.cardInfo
import data.objects.views.meeting.cardAttendees
import data.objects.views.meeting.cardMeetingState
import data.objects.views.task.cardDueDate
import data.objects.views.task.cardTaskState
import data.users.ActiveUserStore
import data.users.views.cardMyUser
import data.users.views.cardOtherUser
import data.users.views.cardUserSelect
import dev.fritz2.components.compat.div
import dev.fritz2.core.RenderContext
import dev.fritz2.routing.MapRouter
import geofenceeditor.cardEditZoneGeofence
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import localization.TL
import mainmenu.AppStateStore
import mainmenu.Pages
import map.views.cardMapLayerSelection
import map.views.cardSharing
import map.views.workplacetools.cardToolSelect
import map.views.workplacetools.cardToolsOverview
import model.AppPhase
import poll.ActivePollStore
import poll.cardPoll
import qrcode.cardConnectQR
import qrcode.cardCreateTrackedObject
import qrcode.cardTrackedObject
import routing.DestinationRoute
import search.nestedObjects.cardNestedObjects
import twcomponents.doOnceWhenElementInDOM
import twcomponents.hideElement
import twcomponents.showElement
import twcomponents.twPullCardWrapper
import utils.isTouchDevice
import webcomponents.KeywordTagType
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer

enum class Cards : DestinationRoute {
    Select,
    POI {
        override val objType = ObjectType.POI
    },
    Task {
        override val objType = ObjectType.Task
    },
    Event {
        override val objType = ObjectType.Event
    },
    Area {
        override val objType = ObjectType.Area
    },
    Zone {
        override val objType = ObjectType.Zone
    },
    GeoFence {
        override val objType = ObjectType.GeoFence
    },
    GeneralMarker {
        override val objType = ObjectType.GeneralMarker
    },
    Sharing,
    MapLayers,
    Tools,
    TrackedObject {
        override val objType = ObjectType.ObjectMarker
    },
    Track,
    CreateTrackedObject,
    ClusterPrev,
    ;

    open val objType: ObjectType? = null
    override val routeKey = "card"
    override val route = mapOf(routeKey to name)
}

const val draggableCardId = "draggable-card"

fun showCard(id: String) {
    doOnceWhenElementInDOM(id) {
        showElement(
            id,
            "+++ Render Card -> set display: block",
        )
    }
}

fun hideCard(id: String) {
    hideElement(
        id,
        "+++ Empty Card -> set display: none",
    )
}

fun RenderContext.cardsNavigator() {

    val router: MapRouter by koinCtx.inject()
    val appStateStore by koinCtx.inject<AppStateStore>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
//    val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
    val activeUserStore by koinCtx.inject<ActiveUserStore>()

    div("z-[1040] ", id = "cards-nav") {
        className(if (isTouchDevice) "transition-none" else "transition-all duration-300 ease-in-out")
        
        twPullCardWrapper(draggableCardId) {
            combine(router.select("page"), appStateStore.data) { (page, params), state ->
                Triple(page, params, state.appPhase)
            }.distinctUntilChanged().render { (page, params, appPhase) ->
                hideCard(draggableCardId)
                console.log("+++ RENDER CARD NAV", params.toString())
                if (
                    parseEnumValue<Pages>(page) == Pages.Map
                    && (
                        (params["ws"].isNotNullOrEmpty() && params.size > 1)
                            || params["ws"].isNullOrBlank() && params.isNotEmpty()
                        )
                    && (appPhase == AppPhase.LoggedIn || appPhase == AppPhase.LoggedInAnonymous)
                ) {
                    showCard(draggableCardId)
//                twPullCardWrapper("draggable-card") {
                    router.select("id").render { (objId, _) ->
                        console.log("+++ SELECT ID!")

                        if (!objId.isNullOrBlank()) {
                            router.select("edit").render { (edit, _) ->
                                console.log("+++ SELECT EDIT!")
                                // try to render routing with current active object ids if possible
//                            if (activeObjectStore.current.id == objId || activeObjectStore.current.tags.getUniqueTag(
//                                    ObjectTags.ExternalId,
//                                ) == objId
//                            ) {
//                                pathEditOrInfo(edit, router)
//                            } else { // otherwise render routing when object in activeObjectStore changed
//                                activeObjectStore.data.render { activeObject ->
//                                    if (activeObject.id == objId || activeObject.tags.getUniqueTag(ObjectTags.ExternalId) == objId) {
//                                        pathEditOrInfo(edit, router)
//                                    }
//                                }
//                            }
                                pathEditOrInfo(edit, router)
                            }
                        } else {
                            router.select("add").render { (add, _) ->
                                console.log("+++ SELECT ADD!")
                                when (add) {
                                    Cards.POI.objType?.name -> pathCreate(ObjectType.POI)
                                    Cards.Task.objType?.name -> pathCreate(ObjectType.Task)
                                    Cards.Event.objType?.name -> pathCreate(ObjectType.Event)
                                    Cards.Zone.objType?.name -> pathCreate(ObjectType.Zone)
                                    Cards.TrackedObject.objType?.name -> pathCreate(ObjectType.ObjectMarker)
                                    else -> {
                                        router.select("card").render { (card, _) ->
                                            console.log("+++ SELECT CARD!")
                                            when (card) {
                                                Cards.Select.name -> {
//                                                halfCard { cardToolSelect() }
                                                    cardToolSelect()
                                                }

                                                Cards.Tools.name -> {
                                                    router.select("change").render { (change, _) ->
                                                        console.log("+++ SELECT CHANGE!")
                                                        when (change) {
                                                            "tags" -> {
//                                                            fullCard(prefix = change) {
//                                                                fullWidthCenterContainer(prefix = "manage-archetype-keyword-tags") {
//                                                                    cardManageKeywordTags(type = KeywordTagType.ArchetypeTag)
//                                                                }
//                                                            }
                                                                cardManageKeywordTags(type = KeywordTagType.ArchetypeTag)
                                                            }

                                                            "fieldValue" -> {
//                                                            fullCard(prefix = change) {
//                                                                fullWidthCenterContainer(prefix = "manage-archetype-field-value-tag") {
//                                                                    cardManageFieldValueTag(tagType = KeywordTagType.ArchetypeTag)
//                                                                }
//                                                            }
                                                                cardManageFieldValueTag(tagType = KeywordTagType.ArchetypeTag)
                                                            }

                                                            else -> {
//                                                            fullCard { fullWidthCenterContainer { cardToolsOverview() } }
                                                                cardToolsOverview()
                                                            }
                                                        }
                                                    }
                                                }

                                                Cards.Sharing.name -> {
//                                                halfCard { cardSharing() }
                                                    cardSharing()
                                                }

                                                Cards.MapLayers.name -> pathMapLayersAndStyles()
                                                Cards.Track.name -> {
//                                                halfCard { cardTrackedObject() }
                                                    cardTrackedObject()
                                                }
//                                            Cards.NFC.name -> expandCard { cardNFC() }
                                                Cards.CreateTrackedObject.name -> {
//                                                halfCard { cardCreateTrackedObject() }
                                                    cardCreateTrackedObject()
                                                }

                                                Cards.ClusterPrev.name -> {
//                                                expandCard { cardClusterPreview() }
                                                    cardClusterPreview()
                                                }

                                                else -> {
                                                    console.log("+++ CARD SELECT -> HIDE!")
                                                    hideCard(draggableCardId)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    console.log("+++ NOT PAGE MAP -> HIDE!")
                    hideCard(draggableCardId)
                }

                router.select("userId").render { (userId, _) ->
                    console.log("+++ SELECT USERID!")
                    if (!userId.isNullOrBlank()) {
                        activeUserStore.data.render { activeUser ->
                            if (activeUser.userId == userId) {
                                showCard(draggableCardId)
//                                expandCard {
//                                    if (userId == apiUserStore.current.userId) {
//                                        cardMyUser()
//                                    } else cardOtherUser()
//                                }
                                if (userId == apiUserStore.current.userId) {
                                    cardMyUser()
                                } else cardOtherUser()
                            } else {
                                console.log("+++ USERID SELECT -> HIDE!")
                                hideCard(draggableCardId)
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun RenderContext.pathEditOrInfo(edit: String?, router: MapRouter) {
    when (edit) {
        Cards.POI.name -> pathEdit(ObjectType.POI)
        Cards.Task.name -> pathEdit(ObjectType.Task)
        Cards.Event.name -> pathEdit(ObjectType.Event)
        Cards.TrackedObject.objType?.name -> pathEdit(ObjectType.ObjectMarker)
        Cards.Area.objType?.name -> pathEdit(ObjectType.Area)
        Cards.Zone.objType?.name -> pathEdit(ObjectType.Zone)
        Cards.GeneralMarker.objType?.name -> pathEdit(ObjectType.GeneralMarker)
        else -> {
            router.select("editPosition").render { (editPosition, _) ->
                when (editPosition) {
                    Cards.TrackedObject.objType?.name -> {
//                            expandCard {
//                                cardEditPosition(
//                                    ObjectType.ObjectMarker,
//                                )
//                            }
                        cardEditPosition(ObjectType.ObjectMarker)
                    }

                    else -> {
                        pathInfo()
                    }
                }
            }
        }
    }
}

fun RenderContext.pathInfo() {
    val router: MapRouter by koinCtx.inject()
    router.select("show").render { (show, _) ->
        when (show) {
            "menu" -> {
//                    expandCard { cardSecondaryMenu() }
                cardSecondaryMenu()
            }

            "details" -> {
//                    expandCard { cardDetails() }
                cardDetails()
            }

            "attribution" -> {
//                    halfCard { cardAttribution() }
                cardAttribution()
            }

            "description" -> {
//                    expandCard(prefix = show) {
//                        fullWidthCenterContainer(prefix = "view-description") {
//                            cardViewDescription()
//                        }
//                    }
                cardViewDescription()
            }

            "taskState" -> {
//                    expandCard { cardTaskState() }
                cardTaskState()
            }

            "meetingState" -> {
//                    expandCard { cardMeetingState() }
                cardMeetingState()
            }

            "attendees" -> {
//                    expandCard(openExpended = true, prefix = show) {
//                        fullWidthCenterContainer(prefix = "attendees") {
//                            cardAttendees()
//                        }
//                    }
                cardAttendees()
            }

            "objectHistory" -> {
//                    expandCard(prefix = show) {
//                        fullWidthCenterContainer(prefix = "object-history") {
//                            cardObjectHistory()
//                        }
//                    }
                cardObjectHistory()
            }

            "tags" -> {
//                    expandCard { cardShowKeywordTags() }
                cardShowKeywordTags()
            }

            "objectData" -> {
//                    expandCard(prefix = show) {
//                        fullWidthCenterContainer(prefix = "object-data", width = { "400px" }) {
//                            cardCustomData()
//                        }
//                    }
                cardCustomData()
            }

            "nestedObjects" -> {
                router.select("change").render { (change, _) ->
                    when (change) {
                        "tags" -> {
//                                fullCard(prefix = change) {
//                                    fullWidthCenterContainer(prefix = "manage-nested-object-keyword-tags") {
//                                        cardManageKeywordTags(type = KeywordTagType.NestedObjectTag)
//                                    }
//                                }
                            cardManageKeywordTags(type = KeywordTagType.NestedObjectTag)
                        }

                        "fieldValue" -> {
//                                fullCard(prefix = change) {
//                                    fullWidthCenterContainer(prefix = "manage-nested-object-field-value-tag") {
//                                        cardManageFieldValueTag(tagType = KeywordTagType.NestedObjectTag)
//                                    }
//                                }
                            cardManageFieldValueTag(tagType = KeywordTagType.NestedObjectTag)
                        }

                        else -> {
//                                expandCard(openExpended = true, prefix = show) {
//                                    fullWidthCenterContainer(prefix = "nested-objects", width = { "400px" }) {
//                                        cardNestedObjects()
//                                    }
//                                }
                            cardNestedObjects()
                        }
                    }
                }
            }

            "markdownPrev" -> {
//                    expandCard(openExpended = true, prefix = show) {
//                        fullWidthCenterContainer(prefix = "markdown-prev", width = { "400px" }) {
//                            cardMarkdownPreview()
//                        }
//                    }
                cardMarkdownPreview()
            }

            "imagePrev" -> {
//                    expandCard(openExpended = true, prefix = show) {
//                        fullWidthCenterContainer(prefix = "image-prev", width = { "400px" }) {
//                            cardImagePreview()
//                        }
//                    }
                cardImagePreview()
            }

            "poll" -> {
//                    fullPage {
//                        fullWidthCenterContainer(prefix = "poll", width = { "600px" }) {
//                            val activePollStore: ActivePollStore by koinCtx.inject()
//                            cardPoll(showBack = true, expandable = false, activePollStore = activePollStore)
//                        }
//                    }
                val activePollStore: ActivePollStore by koinCtx.inject()
                cardPoll(showBack = true, expandable = false, activePollStore = activePollStore)
            }

            "connectQR" -> {
//                    expandCard { cardConnectQR() }
                cardConnectQR()
            }

            else -> {
//                    expandCard { cardInfo() }
                cardInfo()
            }
        }
    }
}

fun RenderContext.pathCreate(objType: ObjectType) {
    val router: MapRouter by koinCtx.inject()
    router.select("change").render { (change, _) ->
        when (change) {
            "desc" -> {
//                    halfCard { cardDescription() }
                cardDescription()
            }

            "datetime" -> {
//                    expandCard { cardDateTime() }
                cardDateTime()
            }

            "duedate" -> {
//                    expandCard { cardDueDate() }
                cardDueDate()
            }

            "participants" -> {
//                    expandCard(openExpended = true, prefix = change) {
//                        fullWidthCenterContainer(prefix = "user-select") {
//                            cardUserSelect()
//                        }
//                    }
                cardUserSelect()
            }

            "badge" -> {
//                    expandCard { cardCustomizeBadge() }
                cardCustomizeBadge()
            }

            "geofence" -> {
//                    expandCard(prefix = change) { cardEditZoneGeofence() }
                cardEditZoneGeofence()
            }

            "tags" -> {
//                    expandCard(openExpended = true, prefix = change) {
//                        fullWidthCenterContainer(prefix = "manage-keyword-tags") {
//                            cardManageKeywordTags(type = KeywordTagType.ObjectTag)
//                        }
//                    }
                cardManageKeywordTags(type = KeywordTagType.ObjectTag)
            }

            "fieldValue" -> {
//                    expandCard(openExpended = true, prefix = change) {
//                        fullWidthCenterContainer(prefix = "manage-field-value-tag") {
//                            cardManageFieldValueTag(tagType = KeywordTagType.ObjectTag)
//                        }
//                    }
                cardManageFieldValueTag(tagType = KeywordTagType.ObjectTag)
            }

//            "attachments" -> expandCard(openExpended = true) { cardManageAttachments() }
//            AttachmentType.Image.name -> expandCard(openExpended = true) { cardManageImageAttachment() }
//            AttachmentType.Markdown.name -> expandCard {
//                fullWidthCenterContainer(prefix = "manage-markdown", width = { "400px" }) {
//                    cardManageMarkdownAttachment()
//                }
//            }
//
//            AttachmentType.WebLink.name -> expandCard(openExpended = true) { cardManageWebLinkAttachment() }
//            AttachmentType.GeoObject.name -> expandCard(openExpended = true) { cardManageGeoObjectAttachment() }
//            AttachmentType.Poll.name -> expandCard(openExpended = true) { cardManagePollAttachment() }
//            AttachmentType.ScanToCreateTask.name -> expandCard(openExpended = true) { cardManageScanToTaskAttachment() }
//            "takePhoto" -> fullCard { cardBrowserPhotoCamera(facingMode = FacingMode.Back) }
            else -> {
//                    expandCard { cardCreate(objType) }
                cardCreate(objType)
            }
        }
    }
}

fun RenderContext.pathEdit(objType: ObjectType) {
    val router: MapRouter by koinCtx.inject()
    router.select("change").render { (change, _) ->
        when (change) {
            "desc" -> {
//                halfCard { cardDescription() }
                cardDescription()
            }

            "datetime" -> {
//                expandCard { cardDateTime() }
                cardDateTime()
            }

            "duedate" -> {
//                expandCard { cardDueDate() }
                cardDueDate()
            }

            "participants" -> {
//                expandCard(openExpended = true, prefix = change) {
//                    fullWidthCenterContainer(prefix = "user-select") {
//                        cardUserSelect()
//                    }
//                }
                cardUserSelect()
            }

            "badge" -> {
//                expandCard(prefix = change) { cardCustomizeBadge() }
                cardCustomizeBadge()
            }

            "geofence" -> {
//                expandCard(prefix = change) { cardEditZoneGeofence() }
                cardEditZoneGeofence()
            }

            "tags" -> {
//                expandCard(openExpended = true, prefix = change) {
//                    fullWidthCenterContainer(prefix = "manage-keyword-tags") {
//                        cardManageKeywordTags(type = KeywordTagType.ObjectTag)
//                    }
//                }
                cardManageKeywordTags(type = KeywordTagType.ObjectTag)
            }

            "fieldValue" -> {
//                expandCard(openExpended = true, prefix = change) {
//                    fullWidthCenterContainer(prefix = "manage-field-value-tag") {
//                        cardManageFieldValueTag(tagType = KeywordTagType.ObjectTag)
//                    }
//                }
                cardManageFieldValueTag(tagType = KeywordTagType.ObjectTag)
            }

            "delete" -> {
//                expandCard { cardEdit(objType) }
                cardEdit(objType)
                fullPageConfirmation { fullPageConfirmationContainer { cardDeleteConfirmation(Delete.Object) } }
            }

//            "attachments" -> expandCard(openExpended = true, prefix = change) { cardManageAttachments() }
//            AttachmentType.Image.name -> expandCard(openExpended = true) { cardManageImageAttachment() }
//            AttachmentType.Markdown.name -> expandCard {
//                fullWidthCenterContainer(prefix = "manage-markdown", width = { "400px" }) {
//                    cardManageMarkdownAttachment()
//                }
//            }

//            AttachmentType.WebLink.name -> expandCard { cardManageWebLinkAttachment() }
//            AttachmentType.GeoObject.name -> expandCard { cardManageGeoObjectAttachment() }
//            AttachmentType.Poll.name -> expandCard { cardManagePollAttachment() }
//            AttachmentType.ScanToCreateTask.name -> expandCard(openExpended = true) { cardManageScanToTaskAttachment() }
//            "takePhoto" -> fullCard { cardBrowserPhotoCamera(facingMode = FacingMode.Back) }
            else -> {
//                expandCard { cardEdit(objType) }
                cardEdit(objType)
            }
        }
    }
}

fun RenderContext.pathMapLayersAndStyles() {

    val router: MapRouter by koinCtx.inject()

    router.select("mapLayer").render { (mapLayer, _) ->
        when (mapLayer) {
            "add" -> {
                router.select("change").render { (change, _) ->
                    when (change) {
                        "fieldValue" -> {
//                            expandCard(openExpended = true, prefix = change) {
//                                fullWidthCenterContainer(prefix = "manage-field-value-tag") {
//                                    cardManageFieldValueTag()
//                                }
//                            }
                            cardManageFieldValueTag()
                        }

                        else -> {
//                            expandCard(openExpended = true) {
//                                fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.CREATE_NEW_LAYER) }
//                            }
                            cardManageMapLayer(TL.LayerAction.CREATE_NEW_LAYER)
                        }
                    }
                }
            }

            "edit" -> {
                router.select("change").render { (change, _) ->
                    when (change) {
                        "fieldValue" -> {
//                            expandCard(openExpended = true, prefix = change) {
//                                fullWidthCenterContainer(prefix = "manage-field-value-tag") {
//                                    cardManageFieldValueTag()
//                                }
//                            }
                            cardManageFieldValueTag()
                        }

                        else -> {
//                            expandCard(openExpended = true) {
//                                fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.EDIT_LAYER) }
//                            }
                            cardManageMapLayer(TL.LayerAction.EDIT_LAYER)
                        }
                    }
                }
            }

            "deleteKeywordLayer" -> {
//                expandCard { fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.EDIT_LAYER) } }
                cardManageMapLayer(TL.LayerAction.EDIT_LAYER)
                fullPageConfirmation { fullPageConfirmationContainer { cardDeleteConfirmation(Delete.KeywordLayer) } }

            }

            "deleteHeatmapLayer" -> {
//                expandCard { fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.EDIT_LAYER) } }
                cardManageMapLayer(TL.LayerAction.EDIT_LAYER)
                fullPageConfirmation { fullPageConfirmationContainer { cardDeleteConfirmation(Delete.HeatmapLayer) } }
            }

            else -> {
//                expandCard { cardMapLayerSelection() }
                cardMapLayerSelection()
            }
        }
    }
}
