@file:Suppress("EXPERIMENTAL_API_USAGE")

package login

import apiclient.groups.WorkSpaceOptions
import auth.ApiUserStore
import auth.WorkspaceOptionsStore
import dev.fritz2.components.clickButton
import dev.fritz2.components.compat.form
import dev.fritz2.components.compat.p
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.Credentials
import model.anonymousAccessAllowed
import model.email
import model.password
import model.signupsAllowed
import network.NetworkState
import network.NetworkStateStore
import search.separationLine
import signup.textButton
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import utils.focusInputObserver
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.fullWidthFullContainer
import webcomponents.genericInput
import webcomponents.inputIconButton
import webcomponents.inputIconToggleButton
import webcomponents.inputLabelWrapper
import webcomponents.mainTitle
import webcomponents.oneButtonFooter

fun RenderContext.pageLogin(workspace: String?, emailParam: String?) {
    val defaultTheme = koinCtx.get<FormationDefault>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val credentialsStore: CredentialsStore by koinCtx.inject()
//    val workspaceNameStore: WorkspaceNameStore by koinCtx.inject()
    val workspaceInputStore: WorkspaceInputStore by koinCtx.inject()
    val workspaceOptionsStore: WorkspaceOptionsStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val email = credentialsStore.map(Credentials.email())
    val password = credentialsStore.map(Credentials.password())
    val openForSignUp = workspaceOptionsStore.map(WorkSpaceOptions.signupsAllowed())
    val anonymousAccess = workspaceOptionsStore.map(WorkSpaceOptions.anonymousAccessAllowed())
    val networkStateStore by koinCtx.inject<NetworkStateStore>()
    val enableNormalLogin = credentialsStore.data.map { it.email.isNotBlank() && it.password.isNotBlank() }
        .combine(workspaceInputStore.data.map { !it.isNullOrBlank() }) { c, w -> c && w }
        .combine(networkStateStore.data.map { it == NetworkState.Online }) { a, n -> a && n }


    if (!workspace.isNullOrBlank()) {
        workspaceInputStore.update(workspace)
    }
    if (!emailParam.isNullOrBlank()) {
        email.update(emailParam)
    }

    if (apiUserStore.anonymousUserStore.current == null && anonymousAccess.current) {
        console.log("No user and no anonymous user set yet -> trigger anonymous login once")
        apiUserStore.anonymousLogin(workspaceInputStore.current)
    }

    // Trigger anonymous login, when loading the login page, if anonymous access should be possible
    combine(apiUserStore.anonymousUserStore.data, anonymousAccess.data) { anonUser, anonAccess ->
        anonAccess && anonUser == null
    }.map { loginAnonymously ->
        if (loginAnonymously) {
            console.log("No user and no anonymous user set yet -> trigger anonymous login")
            apiUserStore.anonymousLogin(workspaceInputStore.current)
        }
    }

    baseLayout(
        expandable = false,
        header = { },
        content = {
            form(
                {
                    width { full }
                    height { full }
                    overflow { auto }
                },
            ) {
                fullWidthFullContainer {
                    div("flex grow-2") { }

                    // Title Formation
//                    span(
//                        "flex flex-grow items-center justify-center text-2xl sm:text-3xl font-thin tracking-widest pl-2",
//                    ) {
//                        +"F O R M A T I O N"
//                    }
//
//                    div("flex grow-1") { }

                    mainTitle(translation[TL.Login.LOGIN])

                    div("flex grow-2") { }
                    div("flex flex-col w-full items-center justify-center mb-3 gap-1") {
//                        className(workspaceInputStore.data.map { ws -> if (ws.isNullOrBlank()) "invisible" else "visible" })
                        cardSubtitle(translation[TL.Signup.WORKSPACE])
                        cardTitle(
                            title = workspaceInputStore.data.map { if (it.isNullOrBlank()) " " else it },
                            margins = { },
                            iconSize = { "30px" },
                            iconFlow = flowOf(FormationIcons.Map.icon), //workspaceInputStore.data.map { if (it.isNullOrBlank()) null else FormationIcons.Map.icon }
                        )
                    }

                    // Proceed Anonymous Button
                    combine(anonymousAccess.data, enableNormalLogin) { anonymousAccess, buttonFocus ->
                        Pair(anonymousAccess, buttonFocus)
                    }.render { (anonymousLogin, focusOnNormalLogin) ->
                        if (anonymousLogin) {
                            div("flex grow-2") { }
                            separationLine(title = translation[TL.Login.OPEN_FOR_ANONYMOUS_ACCESS])
                            div("flex w-full") {
                                attr("onClick", "event.preventDefault(); blurInput();")
                                clickButton(
                                    {
                                        secondaryButtonStyleParams()

                                        // overwrite color params to make button more discoverable
                                        color { secondary.main }
                                        background { color { FormationColors.GreenForest.color } }
                                        border {
                                            width(FormationDefault.formationStyles.borderWidth)
                                            color { FormationColors.GreenForest.color }
                                        }
                                        hover {
                                            color { secondary.main }
                                            background {
                                                color { FormationColors.GreenBright.color }
                                            }
                                            border {
                                                width(FormationDefault.formationStyles.borderWidth)
                                                color { FormationColors.GreenBright.color }
                                            }
                                        }

                                        fontSize { normal }
                                        width { full }
                                    },
                                ) {
                                    type { primary }
                                    text(translation[TL.Login.PROCEED_ANONYMOUSLY])
                                    element {
                                        if (focusOnNormalLogin) attr("type", "button")
                                        attr("tabindex", "4")
                                    }
                                } handledBy apiUserStore.proceedAnonymously
                            }
                            div("flex grow-2") { }
                            p {
                                translation[TL.Login.OR].renderText(into = this)
                            }
                        }
                    }

                    div("flex grow-2") { }

                    separationLine(title = translation[TL.Login.LOGIN_WITH_ACCOUNT])

                    stackUp(
                        {
                            width { full }
                            display { flex }
                            alignItems { stretch }
                        },
                    ) {
                        spacing { small }
                        items {
                            // Input workspace
                            inputLabelWrapper(
                                title = translation[TL.Login.WORKSPACE],
                                visibilityFlow = workspaceInputStore.data.map { !it.isNullOrBlank() },
                            ) {
                                genericInput(
                                    value = workspaceInputStore.data.mapNotNull { it },
                                    rightContentBox = {
                                        inputIconButton(
                                            iconFlow = flowOf { defaultTheme.icons.circleInformation },
                                        ) {
                                            clicks.map { mapOf("show" to "info") } handledBy routerStore.addOrReplaceRoute
                                            attr("tabindex", "-1")
                                        }
                                    },
                                ) {
                                    id("workspace")
                                    placeholder(translation[TL.Login.WORKSPACE])
                                    inputs.values() handledBy workspaceInputStore.update
                                    attr("tabindex", "1")
                                }
                            }

                            // Input email
                            inputLabelWrapper(
                                title = translation[TL.Login.EMAIL],
                                visibilityFlow = email.data.map { it.isNotBlank() },
                            ) {
                                genericInput(
                                    value = email.data,
                                    type = "email",
                                ) {
                                    id("inputEmail")
                                    placeholder(translation[TL.Login.EMAIL])
                                    inputs.values() handledBy email.update
                                    attr("tabindex", "2")
                                }
                            }

                            focusInputObserver({ js("document.getElementById('inputEmail').focus()") }, domNode)

                            // Input password
                            val showPWStore = storeOf(false, job)
                            inputLabelWrapper(
                                title = translation[TL.Login.PASSWORD],
                                visibilityFlow = password.data.map { it.isNotBlank() },
                            ) {
                                genericInput(
                                    value = password.data,
                                    type = "password",
                                    rightContentBox = {
                                        inputIconToggleButton(
                                            iconFalse = { eye },
                                            iconTrue = { eyeOff },
                                            boolStore = showPWStore,
                                            attributes = listOf("tabindex" to "-1"),
                                        )
                                    },
                                ) {
                                    id("inputPassword")
                                    placeholder(translation[TL.Login.PASSWORD])
                                    type(showPWStore.data.map { if (it) "text" else "password" })
                                    attr("tabindex", "3")
                                    inputs.values() handledBy password.update
                                }
                            }
                        }
                    }
                    div("flex grow") { }
                    // Forgot Password button
                    textButton(
                        text = translation[TL.Login.FORGOT_PASSWORD],
                        attributes = listOf(
                            "tabindex" to "-1",
                            "type" to "button",
                            "onClick" to "event.preventDefault();",
                        ),
                        replaceRoute = mapOf("show" to "forgotPassword"),
                    )

                    // Use new sign in via email
//                    textButton(
//                        text = translation[TL.Login.SIGN_IN_VIA_EMAIL_BUTTON],
//                        attributes = listOf(
//                            "tabindex" to "-1",
//                            "type" to "button",
//                            "onClick" to "event.preventDefault();",
//                        ),
//                        replaceRoute = Pages.LoginWithEmail.route,
//                    )

                    openForSignUp.data.render { open ->
                        if (open) {
                            div("flex grow") { }
                            // Sign up Button, if workspace is open for signup
                            textButton(
                                text = translation[TL.Login.DO_NOT_HAVE_ACCOUNT],
                                attributes = listOf(
                                    "tabindex" to "-1",
                                    "type" to "button",
                                    "onClick" to "event.preventDefault();",
                                ),
                                replaceRoute = workspaceInputStore.current?.let { workspace ->
                                    mapOf(
                                        "page" to Pages.SignUp.name,
                                        "ws" to workspace,
                                    )
                                } ?: mapOf("page" to Pages.SignUp.name),
                            )
                        }
                    }
                    div("flex grow-3") { }
                    // Login button
                    div("flex w-full") {
                        attr("onClick", "event.preventDefault(); blurInput();")
                        clickButton(
                            {
                                primaryButtonStyleParams()
                                fontSize { normal }
                                width { full }
                            },
                        ) {
                            type { primary }
                            enabled(enableNormalLogin)
                            text(translation[TL.Login.LOGIN])
                            loadingText(translation[TL.Login.LOGIN_ONGOING])
                            loading(credentialsStore.loggingIn.data)
                            element {
                                attr("type", "submit")
                                attr("tabindex", "5")
                            }
                        } handledBy credentialsStore.login
                    }
                }
            }
        },
    )
}

fun RenderContext.loginInfoPage() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        expandable = false,
        header = {
            cardTitle(translation[TL.Login.LOGIN_NEED_HELP])
        },
        content = {
            contentScrollBox {
                p({ fontSize { small } }) {
                    translation[TL.Login.LOGIN_INFO_TEXT].renderText(into = this)
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}
