package utils

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

class ToggleStore : RootStore<Boolean>(false, Job()) {
    val toggle = handle {
        !current
    }
}
