package routing

import apiclient.FormationClient
import apiclient.users.restGetPublicUserProfile
import auth.ApiUserStore
import auth.TermsStore
import auth.WorkspacesStore
import data.objects.ActiveObjectStore
import data.objects.building.CurrentBuildingsStore
import data.objects.views.objectrouting.LastKnownPositionStore
import data.users.ActiveUserStore
import data.users.UserListStore
import data.users.profile.MyProfileStore
import data.users.settings.SyncedUserPreferencesStore
import db.wipeOPFS
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import location.GeoPositionStore
import location.LocationFollowStore
import location.LocationUploadStore
import login.CredentialsStore
import login.HostConfigStore
import mainmenu.AppStateStore
import mainmenu.Pages
import mainmenu.RouterStore
import map.MapLayersStore
import map.MapObjectStore
import model.AppPhase
import model.AppState
import model.InitializationState
import model.User
import model.toUser
import notifications.GlobalNotificationResultsStore
import point2pointnavigation.RoutingService
import poll.ActivePollStore
import search.PathActiveHighlightedObjectMarkersStore
import search.global.ActiveSearchKeywordsStore
import search.global.GlobalSearchResultsStore
import search.hub.HubSeparateResultsStore
import search.hub.HubUnifiedResultsStore
import search.searchlayer.MapSearchClientsStore
import search.searchlayer.PeriodicSearchStore
import websocket.NotificationClientStore
import websocket.WebsocketService
import wizard.onbaording.OnboardingWizardPageStore


class MainController : RootStore<Boolean>(
    initialData = true,
    job = Job(),
) {

    val formationClient: FormationClient by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    private val syncedUserPreferencesStore: SyncedUserPreferencesStore by koinCtx.inject()
    private val locationUploadStore: LocationUploadStore by koinCtx.inject()
    private val appStateStore: AppStateStore by koinCtx.inject()
    private val workspacesStore: WorkspacesStore by koinCtx.inject()
    private val termsStore: TermsStore by koinCtx.inject()
    private val userListStore: UserListStore by koinCtx.inject()
    private val locationFollowStore: LocationFollowStore by koinCtx.inject()
    private val myProfileStore: MyProfileStore by koinCtx.inject()
    private val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    private val globalSearchResultsStore: GlobalSearchResultsStore by koinCtx.inject()
    private val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    private val geoPositionStore: GeoPositionStore by koinCtx.inject()
    private val mapObjectStore: MapObjectStore by koinCtx.inject()
    private val mapLayersStore: MapLayersStore by koinCtx.inject()
    private val notificationClientStore: NotificationClientStore by koinCtx.inject()
    private val periodicSearchStore: PeriodicSearchStore =
        koinCtx.get() // use get() to make sure it gets initialized here
    private val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    private val websocketService: WebsocketService by koinCtx.inject()
    private val credentialsStore: CredentialsStore by koinCtx.inject()
    private val hostConfigStore: HostConfigStore by koinCtx.inject()
    private val routerStore: RouterStore by koinCtx.inject()
    private val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    private val activeUserStore: ActiveUserStore by koinCtx.inject()
    private val activePollStore: ActivePollStore by koinCtx.inject()
    private val hubUnifiedResultsStore: HubUnifiedResultsStore by koinCtx.inject()
    private val hubSeparateResultsStore: HubSeparateResultsStore by koinCtx.inject()
    private val activeSearchKeywordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
    private val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
    private val onboardingWizardPageStore: OnboardingWizardPageStore by koinCtx.inject()
    private val lastKnownPositionStore: LastKnownPositionStore by koinCtx.inject()


//    val apiUserChangedHandler = handle<User> { current, user ->
//        console.log("api user changed")
//        if (user != emptyUser && user.valid && user.apiUser != null && user.apiUser.hasActivated) {
//            if (validateToken(user)) {
//                console.log("StartRoutine -> Check users terms agreement.")
//                termsStore.checkAndUpdate(user.apiUser)
//                coroutineScope {
//                    launch {
//                        workspacesStore.fetchWorkspaces(user)
//                        if (!user.isAnonymous) {
//                            console.log("StartRoutine -> Fetch user preferences.")
//                            val preferences = syncedUserPreferencesStore.fetchAndInitialize()
//                            preferences.rememberLocationSharing?.let { remember ->
//                                if (remember) preferences.locationSharingState?.let { sharing ->
//                                    locationUploadStore.startSendingLocationsHandler(sharing)
//                                }
//                            }
//                            websocketService.initialize()
//                            notificationClientStore.initialize()
//                            userListStore.fetchGroupMembers(user)
//                        }
//                        periodicSearchStore.initialize()
//                    }
//                }
//            }
//        } else {
//            console.info("empty or invalid user: ${user.userId} (is valid: ${user.valid})")
//        }
//        current
//    }

    private val appStateChangedHandler = SimpleHandler<AppState> { appStateData, _ ->
        appStateData handledBy { appState ->
            if (appState.apiUser != null && (appState.appPhase == AppPhase.LoggedIn || appState.appPhase == AppPhase.LoggedInAnonymous)) {

                appState.apiUser.workspaceName?.let {
                    hostConfigStore.updateHostConfig(it)
                }

                val user = appState.apiUser.toUser()
                console.log("StartRoutine -> User is valid.")
                coroutineScope {
                    launch {
                        workspacesStore.fetchWorkspaces(user)
                        if (!user.isAnonymous) {
                            console.log("StartRoutine -> Fetch user preferences.")
                            val preferences = syncedUserPreferencesStore.fetchAndInitialize()
                            preferences.rememberLocationSharing?.let { remember ->
                                if (remember) preferences.locationSharingState?.let { sharing ->
                                    locationUploadStore.startSendingLocationsHandler(sharing)
                                }
                            }
                            websocketService.initialize()
                            notificationClientStore.initialize()
                            onboardingWizardPageStore.initialize()
                        }
                        userListStore.fetchGroupMembers(user)
                        periodicSearchStore.initialize()
                        pathActivehighlightedObjectMarkersStore.initialize()
                        currentBuildingsStore.initialize()
                        lastKnownPositionStore.initialize()
                    }
                }
            } else {
                console.info("StartRoutine -> User not valid.", appState.toString())
            }
        }
    }

    private suspend fun validateUser(user: User) {
        if (user.apiUser != null) {
            if (validateToken(user)) {
                if (formationClient.restGetPublicUserProfile(userId = user.userId).getOrNull() != null) {
                    console.log("StartRoutine -> User validation succeeded!")
                    apiUserStore.update(user.copy(valid = true))
                } else {
                    console.log("StartRoutine -> User validation failed.")
                    logout()
                }
            } else {
                console.log("StartRoutine -> User refresh token expired.")
                logout()
            }
        } else {
            console.log("StartRoutine -> No user found.")
            logout()
        }
    }

    private fun validateToken(user: User): Boolean {
        console.log("StartRoutine -> Validate refresh token.")
        return if (user.apiUser != null) {
            user.apiUser.apiRefreshToken.expiration > Clock.System.now().toEpochMilliseconds()
        } else false
    }

    val logout = handle {
        logoutRoutine(Unit, apiUserStore.logoutFun)
        it
    }

    val logoutWithParams = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            logoutRoutine(Unit, apiUserStore.logoutWithParams)
        }
    }

    val changeWorkspace = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            logoutRoutine(Unit, apiUserStore.changeWorkspace)
        }
    }

    val stayInWorkspace = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            console.log("Stay in workspace, route to:", Pages.Map.route.toString())
            routerStore.validateExternalRoute(Pages.Map.route)
        }
    }

    val goToSignUp = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            logoutRoutine(Unit, apiUserStore.goToSignUp)
        }
    }

    val logoutToPage = SimpleHandler<Map<String, String>> { data, _ ->
        data handledBy { path ->
            logoutRoutine(path, apiUserStore.logoutToPage)
        }
    }

    private suspend fun <T> logoutRoutine(value: T, logoutFunction: SimpleHandler<T>) {
        coroutineScope {
            launch {
                CoroutineName("logout-routine")
                apiUserStore.isBusyCheckingStore.update(InitializationState.RUNNING)
                wipeOPFS()
                credentialsStore.reset()
                locationUploadStore.stopSendingLocationsHandler(
                    syncedUserPreferencesStore.current.rememberLocationSharing ?: false,
                )
                locationFollowStore.stopFollow()
                geoPositionStore.reset()
                mapLayersStore.reset()
                mapObjectStore.reset()
                myProfileStore.reset()
                termsStore.reset()
                syncedUserPreferencesStore.reset()
                globalSearchResultsStore.reset()
                globalNotificationResultsStore.reset()
                currentBuildingsStore.reset()
                workspacesStore.reset()
                activeObjectStore.resetStore()
                activeUserStore.reset()
                activePollStore.reset()
                hubUnifiedResultsStore.reset()
                hubSeparateResultsStore.reset()
                activeSearchKeywordsStore.reset()
                onboardingWizardPageStore.reset()
                RoutingService.clearNavigationGraph()
                logoutFunction.invoke(value)
                mapSearchClientsStore.clearAllLayerCaches()
                mapSearchClientsStore.reset()
                apiUserStore.isBusyCheckingStore.update(InitializationState.DONE)
            }
        }
    }

    init {
//        apiUserStore.data handledBy apiUserChangedHandler
        appStateStore.data handledBy appStateChangedHandler
    }

}
