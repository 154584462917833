package mainmenu

import data.users.settings.LocalSettingsStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.viewBox
import koin.withKoin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import localization.Locales
import localization.TL
import localization.Translation
import model.LocalSettings
import model.acceptCookies
import model.readDisclaimer
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twCardSectionH4
import twcomponents.twContentScrollBox
import twcomponents.twIconMedium
import twcomponents.twPrimaryButton
import twcomponents.twSecondaryButton
import utils.ToggleStore
import web.dom.document

fun RenderContext.cookieActiveButton(text: Flow<String>, initState: Boolean = false) {

    val state = ToggleStore(initState)

    div("flex flex-row grow gap-2 items-center justify-start cursor-pointer") {
        div("flex w-max p-1 rounded-2xl cursor-pointer") {
            state.data.render { visible ->
                val parent = document.getElementById(cookiePopupId)
                if (visible) {
                    className("hover:bg-gray-300")
                    twIconMedium(icon = FormationUIIcons.Close.icon)
                    parent?.style?.opacity = "1"
                    parent?.style?.height = "384px"
                    parent?.style?.width = "min-content"
                    parent?.style?.minWidth = "240px"
                    parent?.style?.overflow = "auto"
                } else {
                    className("hover:bg-formationWhite")
                    twIconMedium(icon = FormationIcons.Cookie.icon)
                    parent?.style?.opacity = "0.5"
                    parent?.style?.height = "44px"
                    parent?.style?.width = "144px"
                    parent?.style?.minWidth = "144px"
                    parent?.style?.overflow = "hidden"
                }
            }
        }

        p("font-bold") { text.renderText() }

        clicks handledBy state.toggle
    }
}

const val cookiePopupId = "cookie-disclaimer-popup"

fun RenderContext.cookiePopup() {

    withKoin {

        val translation: Translation = get()
        val localSettingsStore: LocalSettingsStore = get()
        val disclaimerLanguageStore: DisclaimerLanguageStore = get()

        combine(
            localSettingsStore.map(LocalSettings.acceptCookies()).data,
            localSettingsStore.map(LocalSettings.readDisclaimer()).data,
        ) { c, d -> Pair(c, d) }.render { (acceptCookies, readDisclaimer) ->
            div(
                baseClass = "flex flex-col gap-1 p-2 w-min min-w-60 h-96 max-h-auto absolute bottom-20 md:bottom-4 left-4 md:left-auto right-auto md:right-32 z-[1020] rounded-2xl bg-formationWhite opacity-50 transition-all duration-300 ease-in-out",
                id = cookiePopupId,
            ) {
                className(if (!acceptCookies || !readDisclaimer) "block" else "hidden")

                if (!acceptCookies) {

                    div("w-max self-start") {
                        cookieActiveButton(text = translation[TL.Cookie.CONSENT_BUTTON])
                    }
                    twContentScrollBox {
                        className("px-1 gap-1")
                        // Big Cookie Icon
                        svg("flex grow h-12 mt-4 shrink-0 self-center text-formationBlack") {
                            viewBox(FormationIcons.Cookie.icon.viewBox)
                            content(FormationIcons.Cookie.icon.svg)
                        }
                        // Accept Cookies Text
                        div("flex grow w-full text-center items-center justify-center") {
                            twCardSectionH4 { translation[TL.Cookie.QUERY].renderText(this) }
                        }

                        p("flex grow text-center") {
                            translation[TL.Cookie.DISCLAIMER].renderText(into = this)
                        }
                    }
                    // Accept Cookies Buttons
                    twSecondaryButton(
                        text = TL.Cookie.ACCEPT_NECESSARY,
                    ) {
                        className("pointer-events-auto")
                        clicks handledBy localSettingsStore.acceptCookies
                    }
                    twPrimaryButton(
                        text = TL.Cookie.ACCEPT,
                    ) {
                        className("pointer-events-auto")
                        clicks handledBy localSettingsStore.acceptCookies
                    }
                } else if (!readDisclaimer) {
                    div("flex min-w-56 grow items-center justify-between") {
                        cookieActiveButton(text = translation[TL.Cookie.DISCLAIMER_BUTTON], initState = true)
                        languageSwitch(store = disclaimerLanguageStore, type = LanguageSwitchType.Disclaimer)
                    }
                    twContentScrollBox {
                        className("px-1 pt-2")
                        // Disclaimer Text
                        disclaimerLanguageStore.data.render { locale ->
                            when (locale) {
                                Locales.EN_GB, Locales.EN_US -> disclaimerTextEN()
                                Locales.DE_DE -> disclaimerTextDE()
                                Locales.ES_ES -> disclaimerTextES()
                                Locales.PT_PT -> disclaimerTextPT()
                                Locales.NL_NL -> disclaimerTextNL()
                                Locales.UA_UA -> disclaimerTextUA()
                                else -> disclaimerTextEN()
                            }
                        }
                    }
                    // Accept Disclaimer Button
                    twPrimaryButton(
                        text = TL.Cookie.ACCEPT,
                    ) {
                        clicks handledBy localSettingsStore.readDisclaimer
                    }
                }
            }
        }
    }
}
